@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.shlk-trackers {
    .shlk-map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0; 
        
        &.updating {
            opacity: 0.7;

            .loader-spinner {
                position: absolute;
                z-index: 1;
            }
        }
    }

    .trackers-content {
        display: flex;
        flex-direction: column;
        gap: 8px 0;

        .trackers-header {
            display: flex;
            align-items: center;
    
            .box-title {
                display: inline-block;
                margin-bottom: 5px;
            }
        }
    
        .trackers-actions {
            display: flex;
            flex-direction: column;
            gap: 15px 0;
            font-size: 14px;

            .actions-select {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .shlk-button {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
    
            .trackers-actions-btn {
                background: none;
                color: black;
                border: none;
                padding: 0;
            }
    
            .trk-icon {
                flex-direction: row;
                gap: 10px;
    
                &-txt {
                    font-weight: bold;
                }
            }
        }
    
        .trackers-warning {
            font-size: 15px;
            margin-top: 10px;
        }
    }
}
