.company-view {
    .company-add-button {
        display: flex;

        .company-add-plus {
            color: var(--shlk-marine);
            line-height: 30px;
            font-size: 30px;
            font-weight: bold;
            margin-right: -5px;
        }

        .company-add-icon {
            width: 30px;
            height: 30px;
            fill: var(--shlk-corporate);
        }
    }
}