

.shlk-free-tracker-qr {
    border: 1px solid #7C9BC6;
    display: inline-block;
    padding: 15px;
    padding-top: 5px;
    text-align: center;
    background: white;

    p {
        margin: 0;
        font-weight: 900;
    }

    .admin-qr {
        height: 130px;
    }

    .admin-qr-logo {
        height: 50px;
    }

    .admin-qr-imgs {
        height: 25px;
        padding: 0 10px;
        box-sizing: border-box;

        .admin-qr-img {
            display: inline-block;
            height: 100%;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}