.waypoint-marker {
    width: 20px;
    height: 20px;
    background-color: #007bff; // Example color
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    // Add any other styling you need here
  }
  