.trackers-info {
    .info-wrapper {
        .info-fields {
            display: flex;
            flex-direction: column;
            gap: 5px 0;
            font-size: 14px;

            .form-label, .tracker-field {
                display: grid;
                grid-template-columns: 80px 1fr;
                gap: 0;
            }

            > * {
                display: flex;
                align-items: center;
                min-height: 28px;
            }
        }

        .info-buttons {
            display: flex;
            flex-direction: column;
            gap: 8px 0;
            margin-top: 15px;
        }
    }

    .info-display {
        display: flex;
        flex-direction: column;
        gap: 15px 0;

        .display-title {
            margin: 0;
            font-size: 16px;
        }

        .display-pickers {
            display: flex;
            flex-direction: column;
            gap: 8px 0;

            .form-input-calendar {
                width: 100%;
            }
        }
    }
}