

.shlk-free-tracker {
    background: var(--shlk-white);
    border: 1px solid var(--shlk-digital);
    border-radius: var(--shlk-border-radius);
    display: inline-block;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 55%;
    h1, p {
        margin: 0;
    }
    input, select {
        display: block;
        margin-top: 10px;
    }
}