.shlk-admin {
    .admin-wrapper {
        padding: 15px;
    }

    .admin-options {
        
    }

    .admin-option {
        display: flex;
        align-items: center;
        background: var(--shlk-white);
        text-decoration: none;
        border: 1px solid var(--shlk-corporate);
        padding: 20px;
        margin: 0 10px 10px 0;
        border-radius: var(--shlk-border-radius);
        h2, p {
            margin: 0;
        }

        .admin-option-text {
            flex-grow: 1;
        }

        .admin-go-icon {
            fill: var(--shlk-corporate);
            height: 50px;
            width: 50px;
        }
    }
}