
.shipment-share {
    img {
        margin-top: 0;
        margin-left: -15px;
        width: 75%;
    }

    .info-btn-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px 0;
    }

    .info-btn {
        height: 25px;
        border: 1px solid var(--shlk-corporate);
        border-radius: var(--shlk-border-radius);
        color: var(--shlk-corporate);
        font-size: 12px;
        margin-right: 5px;
        padding: 0 5px;
        text-decoration: none;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .share-members-header {
        display: flex;
        align-items: center;
        padding-right: 5px;
        margin-bottom: 10px;

        .share-public-title {
            flex-grow: 1;
            margin-bottom: 0;
        }

        .share-add {
            padding: 4px;
            display: flex;
        }
    }

    .share-members-wrapper {
        overflow-y: auto;
        margin-bottom: 50px;
    }

    .share-member {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        border-top: 1px solid var(--shlk-corporate);
        font-size: 12px;

        .share-member-field {
            margin: 5px 0;
        }

        .share-remove {
            font-size: 10px;
            padding: 3px 5px;
            background-color: var(--shlk-red);
            border-color: var(--shlk-red);
            margin-top: 5px;
        }
    }

    .share-group {
        border-top: 1px solid var(--shlk-corporate);
        padding-top: 5px;
    }

    .share-public {
        padding-bottom: 15px;

        &-title {
            margin: 0 0 5px 0;
        }
    }
}