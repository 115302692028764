.table-component {
    height: 100%;
    width: 100%;
    margin: 15px 0;
    overflow: auto;

    .table-component__element {
        text-align: center;
        border-collapse: collapse;
        background-color: white;
        width: 100%;
        font-size: 14px;
    
        table, th, td {
            border: 1px solid var(--shlk-marine);
        }
    
        td, th {
            padding: 4px 8px;
        }

        tr {
            height: 35px;
        }

        thead th {
            position: sticky;
            top: 0;
            background-color: var(--shlk-corporate);
            color: white;
        }
    
        tbody {
            .clickable:hover {
                background-color: var(--shlk-corporate-complementary);
                cursor: pointer;
            }
        }

        .table-component__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--shlk-digital);
            font-weight: bold;
        }
    }
}