.shlk-error-boundary {
    display: flex;
    flex-direction: column;

    .error-boundary-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        height: 100%;
        padding: 0 15px;
        overflow: auto;

        .box-info {
            border-radius: var(--shlk-border-radius);
            padding: 25px 30px;
            background-color:rgba(255, 255, 255, 0.8);
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
            word-wrap: break-word;

            h1 span {
                font-size: 42px;
            }


            .error-boundary-buttons > * {
                margin: 5px 15px;
            }
        }
    }

}