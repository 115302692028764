.shlk-member {
    position: relative;
    display: inline-flex;
    border: 2px solid var(--shlk-marine);
    border-radius: var(--shlk-border-radius);
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 15px 10px 15px 0;
    align-items: center;
    width: 460px;
    height: 90px;
    box-sizing: border-box;

    .shlk-mobile & {
        width: 100%;
    }

    p {
        margin: 0;
        color: var(--shlk-marine);
    } 

    .member-icon {
        width: 50px;
        fill: var(--shlk-marine);
    }

    .memeber-remove {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--shlk-red);
        font-size: 40px;
        font-weight: bold;
        
        line-height: 25px;
        width: 30px;
        height: 30px;
        text-align: center;
    }
}