@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.form-shipment {
    .new-header {
        margin-top: 30px;
        padding-left: 25px;
        padding-bottom: 30px;
        position: relative;

        .new-title {
            margin: 0;
            font-size: 28px;
        }
    
        .new-subtitle {
            margin: 0;
            font-size: 12px;
        }    
    }

    .new-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 15px;

        .new-button {
            display: flex;
            align-items: center;
            font-size: 18px;

            .material-icons {
                margin-right: 5px;
            }
        }
    }

    .add-mode-button {
        margin-bottom: 10px;
        button {
          padding: 10px 15px;
          background-color: var(--shlk-corporate); // Replace with your color variable
          color: white; // Replace with your color variable
          border: none;
          border-radius: var(--shlk-border-radius);
          cursor: pointer;
      
          &:hover {
            background-color: var(--shlk-marine); // Replace with your color variable
          }
        }
      }
    
    .new-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 25px 30px 25px;
        align-items: center;

        .new-wrapper__row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            max-width: 1260px;
            width: 100%;
        }
    }
    .new-wrapper__row--left {
        margin-right: 10px;
        justify-content: flex-start;
    }

    .new-full-width {
        display: flex;
        flex-direction: column;
        width: 100%;
    }


    .new-input-label {
        text-transform: capitalize;
    }

    .new-box {
        box-sizing: border-box;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 4px 10px;
        padding: 10px 15px;
        border-radius: var(--shlk-border-radius);
        width: 100%;
        margin-bottom: 15px;

        &-title {
            margin: 0 0 5px 0;
            font-size: 18px;

            &.location {
                text-transform: capitalize;
            }

            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        &-group {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            width: 100%;

            .new-box {
                width: auto;
            }

            .new-box-trackers {
                min-width: 50%;
                flex-grow: 1;
            }
            
            .new-box-settings {
                flex-grow: 2;
            }
        }

        &-scroll {
            // max-height: 500px;
            // overflow-y: auto;
        }

        .new-input {
            margin-top: 10px;

            .new-input-label {
                display: block;
                font-size: 12px;
                margin-bottom: 5px;
            }

            .new-input-value {
                background-color: var(--shlk-digital-alfa);
                border-radius: var(--shlk-border-radius);
                border: none;
                color: var(--shlk-corporate);
                height: 30px;
                padding: 5px 10px;
                box-sizing: border-box;
                font-size: 12px;
                width: 100%;
                resize: none;

                &::placeholder {
                    color: var(--shlk-corporate);
                    opacity: 0.7;
                }
            }


            

            &.new-input--description {
                margin-top: 15px;

                .new-input-value { 
                    height: 160px;
                }
            }

            &.error {
                .new-input-value {
                    border: 1px solid var(--shlk-red);

                    &:focus {
                        outline: 1px solid var(--shlk-red);
                    }
                }

                .new-input-validation {
                    font-size: 10px;
                    margin-top: 4px;
                    margin-left: 2px;
                    font-weight: bold;
                }
            }
        }

        .new-delete{
            border-radius: var(--shlk-border-radius);
            margin-top: 20px;
            padding: 10px 15px;
            cursor: pointer;
            background-color: var(--shlk-red);
            color: var(--shlk-white);
            border: none;
        }
    }

    .new-box-double {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;

        .new-box-half {
            flex-grow: 1;

            & + .new-box-half {
                min-width: 50%;
            }
        }
    }

    .new-box-general {
        .new-input--name .new-input-label:after { 
            content: "*"
        }
    }
    .new-box-general {
        .new-input--booking .new-input-label:after { 
            content: "*"
        }
    }
    .new-box-general {
        .new-input--containerNumber .new-input-label:after { 
            content: "*"
        }
    }

    .new-box-location {
        position: relative;

        .new-input.address .new-input-label:after { 
            content: "*"
        }

        .new-location-map {
            position: relative;
            height: 195px;

            .map-loading-wrapper {
                position: absolute;
                top: 0; 
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--shlk-grey);
                z-index: 1;
                opacity: 0.5;

                display: flex;
                align-items: center;
                justify-content: center;

                .map-loading {
                    -webkit-animation: rotating 1s linear infinite;
                    pointer-events: none;
                }
            }
        }
    }

    .new-box-tags {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px 10px;

        .tags-header {
            display: flex;
            align-items: center;
            margin: 5px 0 5px 5px;

            .new-box-title {
                margin: 0;
            }

            .input-element {
                width: unset;
            }
        }


        .tags-output {
            .tags-container {
                flex-wrap: wrap;
    
                .tags-tag {
                    margin: 5px;
                    font-size: 16px;
                }
            }
        }
    }

    .new-tracker {
        position: relative;
        border-bottom: 1px solid var(--shlk-corporate);
        margin: 5px 15px 10px 0;
        padding-bottom: 15px;

        .tracker-info {
            padding-right: 20px;
            margin-right: 0;
            box-sizing: border-box;
        }

        .new-tracker-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 20px;
            fill: var(--shlk-corporate);
        }
    }

    .new-remove-settings {
        display: flex;
    }
}

.shlk-desktop {
    .form-shipment {
        .form-settings .form-input {
            width: 80px;
        }
    }
}

.shlk-mobile {
    .form-shipment {
        .new-wrapper {
            .new-box {
                width: 100%;
                margin-bottom: 15px;
            }  

            .new-box-group {
                margin-bottom: 15px;

                .new-box {
                    margin-bottom: 0;
                } 
            } 
    
            .new-box-location {
                margin-right: 0;
            }
        }
    }
}

.transport-chain {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%; /* Make the container take full width */
  
    margin-top: 10px;
    margin-bottom: 10px;
  }
  


  @media screen and (min-width: 767px) {
    .shipment-reference{
        max-width: 40%;

    }
  }

  
  @media screen and (max-width: 767px) {
    .form-shipment {
    .new-box-double {

        gap: 0px;}
  }
.empty{
    display: none;
}
.shipment-reference{
  margin-top: 10px;
    
}
}