// .shipment-card__container {
//   display: flex;
//   flex-direction: column;
//   width: 371px;
//   margin: 5px 20px 5px 0;
//   font-family: "Roboto Condensed";
//   font-size: 14px;
//   color: var(--shlk-marine);
//   &.expanded {
//     width: auto;
//   }
//   .shipment-card__box {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
//     padding: 0;
//     margin-bottom: 6px;
//     box-shadow: rgba(61, 95, 160, 0.6) 1px 2px 10px;

//     .shipment-card__top {
//       display: flex;
//       justify-content: space-between;
//       padding: 20px;
//       box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
//       border-bottom: 1px solid #ccc;
//       flex-grow: 1;
//       align-items: flex-start;

//       .shipment-card__info {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         flex-direction: column;
//         justify-content: space-between;
//         width: 100%;

//         .shipment-card__field {
//           margin: 0 0 5px 5px;
//           font-weight: 600;
//           white-space: nowrap;

//           &.left {
//             font-weight: bold;
//             margin-bottom: 15px;
//           }
//           &.right {
//             font-weight: bold;
//             justify-self: end; /* Aligning text to the end (right) */
//             margin-bottom: 15px;
//           }
//           &.address {
//             font-weight: bold;
//           }

//           &.name {
//             font-weight: bolder !important;
//             display: flex;
//             align-items: center;
//           }

//           &.event-icon-box {
//             display: flex;
//             align-items: center;
//             margin-left: 0px;
//           }

//           &.expanded-event {
//             width: 500px !important;
//             background-color: var(--shlk-corporate-light);
//             padding: 5px 10px;
//             border-radius: 4px;
//           }
//         }

//         .shipment-card__tags {
//           .tags-container {
//             flex-wrap: nowrap;
//             .tags-tag {
//               margin: 8px 0px 5px 5px;
//               font-weight: bold;
//             }
//           }
//         }
//       }

//       .shipment-card__chevron {
//         display: flex;
//         align-items: center;
//       }
//     }

//     .shipment-card__bottom {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       margin: 0 10px;
//       height: 35px;

//       .shipment-card__alerts {
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//         flex-grow: 1;

//         .event-icon__transport {
//           padding: 4px;
//           background-color: var(--shlk-coral);
//           margin: 0 5px;
//           border-radius: 50%;
//         }
//       }

//       .event-icon__shipment {
//         margin: 0 5px;
//         fill: var(--shlk-corporate);

//         svg {
//           display: block;
//           height: 22px;
//           width: 22px;
//         }
//       }
//     }
//   }

//   .shipment-card__bar {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin: 0 2px;

//     .shipment-bar {
//       flex-grow: 1;
//       position: relative;
//       border-radius: 20%;
//       background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);
//       background-color: var(--shlk-corporate-complementary);
//     }

//     .shipment-bar__progress {
//       position: absolute;
//       height: 100%;
//       border-radius: var(--shlk-border-radius);
//       background-color: var(--shlk-corporate);
//     }

//     .shipment-bar__event {
//       position: absolute;
//       height: 100%;
//       width: 2px;
//       background-color: var(--shlk-coral);

//       &.ack {
//         background-color: #989595;
//       }
//     }

//     .shipment-bar__percentage {
//       margin-left: 5px;
//       font-weight: bold;
//     }
//   }
// }

// .shlk-card {
//   width: 100%;
//   box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
//   border-radius: var(--shlk-border-radius);
//   padding: 20px 15px;
//   box-sizing: border-box;
//   display: inline-block;
//   text-decoration: none;
//   margin-bottom: 15px;
//   overflow: hidden;
//   background-color: var(--shlk-white);
// }

// .shipment-card__carbon {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background-color: var(--shlk-corporate-light);
//   padding: 5px 10px;
//   border-radius: 4px;
//   width: 170px;
//   height: 33px;

//   &.warnings {
//     color: #8b8b8b;
//   }

//   &.expanded {
//     width: auto;
//   }

//   .dynamic-data {
//     color: #2b8e8f;
//     font-size: 16px;
//     font-weight: bold;
//   }

//   .static-data {
//     color: #8b8b8b;
//     font-size: 13px;
//     font-weight: bold;
//   }
// }

// .shipment-card__bar.full_width {
//   grid-column: 1 / -1; /* Span all columns */
//   margin-bottom: 15px;
// }

// .shipment-bar__point {
//   position: absolute;
//   width: 9px; /* Adjust size as needed */
//   height: 9px; /* Adjust size as needed */
//   top: -px;
//   background-color: #8b8b8b; /* Adjust color as needed */
//   border-radius: 50%; /* Makes the points circular */
//   transform: translateX(-50%);
//   &.expanded {
//     background-color: #8b8b8b;
//   }
// }
// .shipment-container {
//   position: relative; /* Container needs to be relative */
// }

// .shipment-type {
//   position: absolute;
//   top: -30px; /* Adjust this value as needed to position the shipment type */
//   left: 50%;
//   transform: translateX(-100%);
//   white-space: nowrap;
//   color: #19575c;
//   font-weight: bold;

//   &.time {
//     left: 95% !important;
//   }
// }

// .shipment-bar__point.done {
//   background-color: #19575c;
//   z-index: 1;
// }

// .shipment-bar__point.beyond-progress {
//   background-color: grey; /* Change the background color as needed */
// }

// .event-icon-1 {
//   color: white;
//   background-color: var(--shlk-red);
//   box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
//   border-radius: 50%;
//   padding: 10px;
//   margin-bottom: 10px;
//   width: 25px;
//   height: 25px;
// }

// .event-icon-idle-1 {
//   color: white;
//   background-color: var(--shlk-green);
//   box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
//   border-radius: 50%;
//   padding: 10px;
//   margin-right: 10px;
//   width: 25px;
//   height: 25px;
// }

// .shipment-bar__line.hovered {
//   background: repeating-linear-gradient(
//     90deg,
//     blue 0 9px,
//     white 0 15px
//   ); /* Change to your desired color */
// }

// .shipment-bar__line {
//   width: 100px; /* Adjust the width of the line */
//   height: 3px; /* Adjust the height of the line */
//   background-color: #8b8b8b; /* Set the color of the line */
//   position: absolute;
//   background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);

//   &.expanded {
//     background-color: #8b8b8b !important;
//     background: repeating-linear-gradient(
//       90deg,
//       #8b8b8b 0 9px,
//       white 0 15px
//     ) !important;
//   }
// }

// .shipment-progress-box {
// }

// .shipment-bar__line.done {
//   background: #19575c !important;
// }

// .container-number {
//   margin-right: 80px; /* Add spacing between the container number and "show more" */
// }

// .show-more {
//   cursor: pointer; /* Add pointer cursor to indicate interactivity */
//   /* Add any other styles you need for the "show more" button */
// }

// .actual-time-arrival {
//   color: #2b8e8f;
// }

// .estimated-time-arrival {
//   color: #8b8b8b;
// }

// .shipment-bar__icon {
//   &.expanded {
//     position: absolute;
//     left: 100%;
//     transform: translateX(-50%);
//     top: -15px;
//     background-color: #19575c;
//     border-radius: 50%;
//     padding: 5px;
//   }
// }

// .shipment-card__carbon.expanded.warnings {
//   /* Default styles */
//   white-space: nowrap;
// }

// @media screen and (max-width: 767px) {
//   .shipment-card__container {
//     width: 100% !important;
//     &.expanded {
//       width: 100% !important;
//     }
//   }
//   .mobile-width {
//     display: flex !important;
//   }
//   .expanded-event {
//     width: 330px !important;
//     padding: 0 !important;
//   }

//   .shipment-card__carbon {
//     &.expanded {
//       height: auto;
//     }
//   }
//   .shipment-card__carbon.expanded.events {
//     white-space: normal;
//     margin-left: 0; /* Adjust margin for mobile */
//   }

//   .shipment-card__container {
//     .shipment-card__box {
//       .shipment-card__top {
//         .shipment-card__info {
//           .shipment-card__field {
//             &.expanded-event {
//               width: 100% !important;

//               padding: 0px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .tags-manager__icon {
//   margin-right: 5px;
//   height: 18px;
//   width: 18px;
//   fill: var(--shlk-white);
// }

// .info-icon{
//   margin-right: 5px;
//   margin-bottom: 1.9px;
//   height: 15px;
//   width: 15px;
//   fill: var(--shlk-white);
// }

// .tags-manager {
//   display: flex;
//   background-color: #19575c;
//   color: #fff;
//   width: 71px;
//   height: 20px;
// }

// table {
//   width: 100%;
//   border-collapse: collapse;
// }

// td:first-child {
//   text-align: left;
// }

// td:last-child {
//   text-align: right;
// }

// .tag-margin{
//   margin-bottom: 0px;
// }

// .align-tag{
//   display: flex;
//   align-items: center;
// }

// @media screen and (max-width: 767px) {
//   .scrollable-content {
//     margin-top: 150px; /* Adjust according to the height of the fixed section */
//     overflow-y: auto;
//     // max-height: calc(100vh - 150px); /* Adjust according to the height of the fixed section */
//     scrollbar-width: thin;
//   }}

.shipment-card__container {
  display: flex;
  flex-direction: column;
  width: 371px;
  margin: 5px 20px 5px 0;
  font-family: "Roboto Condensed";
  font-size: 14px;
  color: var(--shlk-marine);
  &.expanded {
    width: auto;
  }
  .shipment-card__box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin-bottom: 6px;
    box-shadow: rgba(61, 95, 160, 0.6) 1px 2px 10px;

    .shipment-card__top {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
      border-bottom: 1px solid #ccc;
      flex-grow: 1;
      align-items: flex-start;

      .shipment-card__info {
        display: flex;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .shipment-card__field {
          margin: 0 0 5px 5px;
          font-weight: 600;
          white-space: nowrap;

          &.left {
            font-weight: bold;
            margin: 0px !important;
          }
          &.right {
            font-weight: bold;
            justify-self: end; /* Aligning text to the end (right) */
            margin: 0px !important;
          }
          &.address {
            font-weight: bold;
          }

          &.name {
            font-weight: bolder !important;
            display: flex;
            align-items: center;
          }

          &.event-icon-box {
            display: flex;
            align-items: center;
            margin-left: 0px;
            margin-bottom: 15px !important;
          }

          &.expanded-event {
            width: 500px !important;
            background-color: var(--shlk-corporate-light);
            padding: 5px 10px;
            border-radius: 4px;
          }
        }

        .shipment-card__tags {
          .tags-container {
            flex-wrap: nowrap;
            .tags-tag {
              margin: 8px 0px 5px 5px;
              font-weight: bold;
            }
          }
        }
      }

      .shipment-card__chevron {
        display: flex;
        align-items: center;
      }
    }

    .shipment-card__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 10px;
      height: 35px;

      .shipment-card__alerts {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        .event-icon__transport {
          padding: 4px;
          background-color: var(--shlk-coral);
          margin: 0 5px;
          border-radius: 50%;
        }
      }

      .event-icon__shipment {
        margin: 0 5px;
        fill: var(--shlk-corporate);

        svg {
          display: block;
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .shipment-card__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px !important;
    margin-bottom: 15px !important;

    .shipment-bar {
      flex-grow: 1;
      position: relative;
      border-radius: 20%;
      background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);
      background-color: var(--shlk-corporate-complementary);
    }

    .shipment-bar__progress {
      position: absolute;
      height: 100%;
      border-radius: var(--shlk-border-radius);
      background-color: var(--shlk-corporate);
    }

    .shipment-bar__event {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: var(--shlk-coral);

      &.ack {
        background-color: #989595;
      }
    }

    .shipment-bar__percentage {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}

.shipment-card__container{
  .shipment-card__bar_details_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 25px;


    .shipment-bar {
      flex-grow: 1;
      position: relative;
      border-radius: 20%;
      background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);
      background-color: var(--shlk-corporate-complementary);
    }

    .shipment-bar__progress {
      position: absolute;
      height: 100%;
      border-radius: var(--shlk-border-radius);
      background-color: var(--shlk-corporate);
    }

    .shipment-bar__event {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: var(--shlk-coral);

      &.ack {
        background-color: #989595;
      }
    }

    .shipment-bar__percentage {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}

.shlk-card {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  border-radius: var(--shlk-border-radius);
  padding: 20px 15px;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: var(--shlk-white);
}

.shipment-card__carbon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--shlk-corporate-light);
  padding: 5px 10px;
  border-radius: 4px;
  width: 170px;
  height: 33px;

  &.warnings {
    color: #8b8b8b;
  }

  &.expanded {
    width: auto;
  }

  .dynamic-data {
    color: #2b8e8f;
    font-size: 16px;
    font-weight: bold;
  }

  .static-data {
    color: #8b8b8b;
    font-size: 13px;
    font-weight: bold;
  }
}

.shipment-card__bar.full_width {
  grid-column: 1 / -1; /* Span all columns */
  margin-bottom: 15px;
}
.shipment-card__bar_details_bar.full_width {
  grid-column: 1 / -1; /* Span all columns */

}
.shipment-card__bar_tags.full_width{
  grid-column: 1 / -1; /* Span all columns */
  margin: 0px !important;
}
.shipment-bar__point {
  position: absolute;
  width: 9px; /* Adjust size as needed */
  height: 9px; /* Adjust size as needed */
  top: -px;
  background-color: #8b8b8b; /* Adjust color as needed */
  border-radius: 50%; /* Makes the points circular */
  transform: translateX(-50%);
  &.expanded {
    background-color: #8b8b8b;
  }
}
.shipment-container {
  position: relative; /* Container needs to be relative */
}

.shipment-type {
  position: absolute;
  top: -30px; /* Adjust this value as needed to position the shipment type */
  left: 50%;
  transform: translateX(-100%);
  white-space: nowrap;
  color: #19575c;
  font-weight: bold;

  &.time {
    left: 95% !important;
  }
}

.shipment-bar__point.done {
  background-color: #19575c;
  z-index: 1;
}

.shipment-bar__point.beyond-progress {
  background-color: grey; /* Change the background color as needed */
}

.event-icon-1 {
  color: white;
  background-color: var(--shlk-red);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 10px;
  width: 25px;
  height: 25px;
}

.event-icon-idle-1 {
  color: white;
  background-color: var(--shlk-green);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.shipment-bar__line.hovered {
  background: repeating-linear-gradient(
    90deg,
    blue 0 9px,
    white 0 15px
  ); /* Change to your desired color */
}

.shipment-bar__line {
  width: 100px; /* Adjust the width of the line */
  height: 3px; /* Adjust the height of the line */
  background-color: #8b8b8b; /* Set the color of the line */
  position: absolute;
  background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);

  &.expanded {
    background-color: #8b8b8b !important;
    background: repeating-linear-gradient(
      90deg,
      #8b8b8b 0 9px,
      white 0 15px
    ) !important;
  }
}

.shipment-progress-box {
}

.shipment-bar__line.done {
  background: #19575c !important;
}

.container-number {
  margin-right: 0px !important;
}

.show-more {
  cursor: pointer; /* Add pointer cursor to indicate interactivity */
  /* Add any other styles you need for the "show more" button */
}

.last-updated{
  margin-top: 15px !important;
}

.actual-time-arrival {
  color: #2b8e8f;
}

.estimated-time-arrival {
  color: #8b8b8b;
}

.shipment-bar__icon {
  &.expanded {
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    top: -15px;
    background-color: #19575c;
    border-radius: 50%;
    padding: 5px;
  }
}

.shipment-card__carbon.expanded.warnings {
  /* Default styles */
  white-space: nowrap;
}

.tags{
  margin-bottom: 0px !important;
}

@media screen and (max-width: 767px) {
  .shipment-card__container {
    width: 100% !important;
    &.expanded {
      width: 100% !important;
    }
  }
  .mobile-width {
    display: flex !important;
  }
  .expanded-event {
    width: 330px !important;
    padding: 0 !important;
  }

  .shipment-card__carbon {
    &.expanded {
      height: auto;
    }
  }
  .shipment-card__carbon.expanded.events {
    white-space: normal;
    margin-left: 0; /* Adjust margin for mobile */
  }

  .shipment-card__container {
    .shipment-card__box {
      .shipment-card__top {
        .shipment-card__info {
          .shipment-card__field {
            &.expanded-event {
              width: 100% !important;

              padding: 0px;
            }
          }
        }
      }
    }
  }
}

.tags-manager__icon {
  margin-right: 5px;
  height: 18px;
  width: 18px;
  fill: var(--shlk-white);
}

.info-icon {
  margin-right: 5px;
  margin-bottom: 1.9px;
  height: 15px;
  width: 15px;
  fill: var(--shlk-white);
}

.tags-manager {
  display: flex;
  background-color: #19575c;
  color: #fff;
  width: 71px;
  height: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td:first-child {
  text-align: left;
}

td:last-child {
  text-align: right;
}

.tag-margin {
  margin-bottom: 0px;
}

.align-tag {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .scrollable-content {
    margin-top: 150px; /* Adjust according to the height of the fixed section */
    overflow-y: auto;
    // max-height: calc(100vh - 150px); /* Adjust according to the height of the fixed section */
    scrollbar-width: thin;
  }
}
