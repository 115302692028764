@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

// Login styles
.shlk-login {
    height: 100vh;

    background-color: var(--shlk-black);
    background-image: none;
    min-height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .shlk-loading-icon {
        width: 30px;
        margin: auto;
        padding-top: 5px;
        fill: white;

        -webkit-animation: rotating 1s linear infinite;
    }

    .form-errors {
        color: white;
    }
}

.shlk-login .shlk-ui {
    display: none;
}

.shlk-login-wrapper {
    width: 300px;
    margin: auto;
}

.shlk-login-logo {
    width: 150px;
    margin-bottom: 50px;
}

.shlk-login-form {
    .shlk-form__container {
        background-color: white;
        border-radius: var(--shlk-border-radius);
        padding: 3px;
      
  

        .shlk-login-pwd,
        .shlk-login-email {
            padding-left: 15px;
        }

        .shlk-login-pwd {
            // border-top: 1px solid #CCC;
        }
    }

    .shlk-login-input {
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        border:none;
        background: none;
        font-size: 16px;
    }

    .shlk-login-remember {
      
        display: flex;
        align-items: center;
        color: white;
justify-content: space-between;
        span { margin-left: 2px; }
       font-family: "Roboto Condensed";
    }

    .shlk-login-btn {
        background-color: var(--shlk-marine);
        color: white;
        border-radius: var(--shlk-border-radius);
     
    }

    .shlk-login__loading {
        opacity: 0.8;
    }
}
////new




.shlk-login-options {
    margin-top: 10px;
    text-align: right;
}

.shlk-forgot-password {
    background: none;
    border: none;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-family: "Roboto Condensed"; 

}

.Forgot-Password{
 color: white;
    font-size: 20px;
    font-family: "Roboto Condensed";  
}
.Forgot-Password-text{
    font-size: 16px;
    font-family: "Roboto Condensed"; 
    color: white;
    margin: 10px 0;
}
.error-message{
    color: #c11c1c;
    font-size: 16px;
    font-family: "Roboto Condensed"; 
    margin: 10px 0 0 0;
}
.back-to-login {
    cursor: pointer;
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
   
    font-family: "Roboto Condensed"; // Ensure consistent font-family
  
    &:hover {
      text-decoration: none;
    }
  }


  .padding_adjustment
  {
    margin-top: 10px;
  }

  .action-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .resend-code-btn{
    color: white;
    background: none;
    border: none;
    font-size: 16px;
    font-family: "Roboto Condensed"; 
    cursor: pointer;
  }
  .info{
    top: 2px !important;
  }
  .wrap-password{
    display: flex;
    align-items: center;
    border-top: 1px solid #CCC;
  }
  .show-hide-btn{
    margin-right: 10px;
    cursor: pointer;
  }