
.shipment-settings {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &.shipment-settings__completed {
        input {
            background-color: #e7e7e7;
            color: grey;
        }
        .form-selectable-check {
            fill: darkgrey;
        }
    }

    .settings-btn-wrapper {
        margin-top: 15px;
    }
}

