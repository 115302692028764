.shlk-vessel__popup {
    .vessel-popup__title {
        text-align: center;
        margin: 0 0 12px;
        font-size: 16px;
    }

    .vessel-popup__info {
        font-size: 14px;
        margin-bottom: 5px;

        .info-field {
            margin-bottom: 3px;
        }
    }

    .vessel-popup__button {
        display: flex;
        justify-content: center;
        font-size: 10px;
        flex-shrink: 1;
        text-align: center;
        align-items: center;

        .shlk-button {
            flex-grow: 1;
        }

        .popup-shipment {
            padding: 0 10px;
            
            p {
                margin: 0;
            } 
        }
    }
}