.shlk-fleet-all {
    .fleet-form__container {
        margin: 10px 0;
    
        .fleet-form__element {
            display: flex;
            flex-direction: column;

            .form-info__text {
                padding: 6px 10px;
                width: 120px;
                font-size: 12px;
                text-align: justify;
            }

            .form-label {
                display: block;
                margin-right: 0;

                .form-icon {
                    top: 12px;
                    left: 10px;
                    right: auto;
                }

                .form-input {
                    width: 100%;
                    padding-left: 32px;
                    height: 40px;
                }
            }            
        }
    }

    .fleet-form__disclaimer {
        font-size: 10px;
        margin: 5px 0 10px 0;
    }
}