.shlk-fleet {
    .shlk-vessel-map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .fleet-subtitle {
        margin: -10px 0 0 0;
        font-weight: 500;

        b {
            margin-right: 2px;
        }
    }

    .fleet-list__disclaimer {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .fleet-list__container {
        flex-grow: 1;
        padding: 0;
        margin: 0;
        margin-top: 10px;

        ul {
            margin: 0;
            padding: 0;
        }
    }
}
