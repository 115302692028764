.shlk-box {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  min-height: 300px;
  z-index: 1;

  .box-button {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 10px;

    &.back {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin-bottom: 12px;
      gap: 1px 0;
      font-weight: bold;

      .box-button-back {
        color: var(--shlk-transparent);
        background-color: var(--shlk-marine);
        border-radius: 50%;
        padding: 4px;
      }
    }

    &-menu {
      color: var(--shlk-corporate);
    }

    .bc-wrapper {
      gap: 10px;
    }
  }

  .box-content-menu {
    width: 60px;
    box-sizing: border-box;
    padding-top: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--shlk-white);

    .box-menu-button {
      display: flex;
      justify-content: center;
      padding: 10px 0;

      &--active {
        border-left: 5px solid var(--shlk-marine);

        .box-menu-icon {
          fill: var(--shlk-marine);
          margin-left: -5px;
        }
      }

      &--notification {
        .box-menu-icon:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: block;
          background-color: var(--shlk-red);
        }
      }
    }

    .box-menu-icon {
      fill: var(--shlk-digital);
      position: relative;
      width: 27px;
      height: 27px;
    }
  }

  .box-content {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
    position: relative;
    min-width: 15rem;
  
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--shlk-white);
    border-radius: 10px;

    .box-header {
      font-size: 18px;
      margin: 0;
      margin: 5px 0;
    }

    .box-content__element {
      height: 100%;
      margin-left: 20px;
      margin-top: 20px;
    }
  }

  .box-top-button {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    box-sizing: border-box;

    .box-top-icon {
      width: 100%;
      height: 100%;
      fill: var(--shlk-corporate);
    }

    .box-top-counter {
      position: absolute;
      right: 5px;
      background-color: var(--shlk-red);
      color: white;
      font-size: 11px;
      border-radius: 50%;
      text-align: center;
      top: 5px;
      padding: 1px 4px;
      min-width: 10px;
    }
  }

  &.shlk-box--close {
    .box-content {
      display: none;
    }
  }

  // Functional Classes
  .bc-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 10px;
    width: auto;
    &.expanded {
      margin-right: 200px;
    }
  }

  .bc-stretch {
    flex-grow: 1;
  }
}

.box-content__element {
  margin-top: 5px;
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .box-content__element {
    // margin-left: 0px !important;
    // margin-top: 0px !important;
    margin: 0px;
  }
  .box-content-menu {
    z-index: 2;
    }
  .box-content {
    border-radius: 0px !important;}
}

.box-menu-icon-hover {
  transition: fill 0.3s ease;
}

.box-menu-button:hover .box-menu-icon-hover {
  fill: #19575c; /* Replace with your desired hover color */
}

.scroll {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.back-button {
  background: white;
  height: 100px;
  width: 60px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  z-index: 3;
}
