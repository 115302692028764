@keyframes slideUp {
    from { transform: translate3d(0, 110%, 0); }
    to { transform: translate3d(0, 0, 0); }
}  

@keyframes slideDown {
    from { transform: translate3d(0, 0, 0); }
    to { transform: translate3d(0, 110%, 0); }
}  

@keyframes turnUpside {
    from { transform: rotateZ(180deg); }
    to { transform: rotateZ(0deg); }
}  

@keyframes blackingOut {
    from { opacity: 0; }
    to { opacity: 0.7; }
} 

.shlk-content-popup,
.shlk-cp-card,
.shlk-cp-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;   
}


.shlk-content-popup {
    z-index: 10;
}

.shlk-cp-card {
    animation-duration: 1s; 
    animation-name: slideUp; 
    transform: translate3d(0, 0, 0);

    background-color: var(--shlk-transparent);
    backdrop-filter: blur(6px);
    max-height: 85vh;
    height: 100%;

    .shlk-desktop & {
        top: auto;
        bottom: 0;
        height: 100%;
        max-height: 90%;
    }
    .shlk-mobile & {
        top: auto;
    }
}

.shlk-cp-background {
    background-color: #000;
    transition: opacity 1s cubic-bezier(0.68, 0.07, 0.9, 1);
    animation-duration: 1s; 
    animation-name: blackingOut; 
    opacity: 0.7;
}

.shlk-cp-closing {
    .shlk-cp-card {
        animation-name: slideDown;
        animation-fill-mode: forwards;
        pointer-events: none;
    }
    .shlk-cp-background {
        opacity: 0; 
    }
}

.shlk-cp-close {
    color: var(--shlk-transparent);
    transform: rotateZ(180deg);
    
    animation-name: turnUpside;
    animation-duration: 0.2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    pointer-events: auto;

    &-wrapper {
        background-color: var(--shlk-corporate);
        display: inline-flex;
        border-radius: 100%;
    }
}

.shlk-cp-header {
    position: absolute;
    top: -20px;
    text-align: center;
    width: 100%;
    display: inline-block;
    z-index: 10;
    pointer-events: none;
}

.shlk-cp-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    border-top: 2px solid var(--shlk-marine);
}