@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.shipment-page {
    .shlk-map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }   

    .map-btn {
        width: 40px;
        height: 40px;

        position: absolute;
        // z-index: 1;
        right: 60px;
        padding: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: var(--shlk-marine); 
        color: var(--shlk-white); 
        
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .center-btn {
        bottom: 120px;
        right: 10px;
        top: auto;
    }

    .refresh-btn {
        top: 10px;

        &:hover {
            -webkit-animation: rotating 1s linear infinite;
        }

        .shlk-mobile & {
            top: 60px;
            right: 10px;   
        }
    }
}