.shlk-filter__container {
  // margin-bottom: 15px;
  // margin-top: 20px;

  .filter-inputs {
    // display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    &__search {
      display: inline-block;
    }

    &__tags {
      display: inline-block;

      .input-element {
        margin: 0;
      }
    }

    &__date {
      display: inline-block;

      .form-label {
        margin-right: 0;
      }
    }

    &__ongoing,
    &__completed {
      align-self: center;
    }
  }

  .tags-container {
    flex-wrap: wrap;

    .tags-tag {
      margin: 10px 10px 10px 0;
    }
  }
}

.shlk-button {
  text-decoration: none;
  border: 2px solid var(--shlk-corporate);
  border-radius: 5px;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  color: var(--shlk-corporate);
  background-color: transparent;
  cursor: pointer;
  float: right;
  // margin-right: 10px;
  // margin-bottom: 10px;

  &.shlk-button__solid {
    color: white;
    background-color: #19575c;
  }
}
.filter {
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .shlk-title {
    margin: 0px;
  }
  .filter {
    margin-left: 0px;
  }
  .filter-outercontainer {
    flex-direction: column;
  }
  .gap {
    gap: 10px !important;
  }
  .width {
    width: 43% !important;
  }
}
.gap {
  gap: 3px;
}
.width {
  gap: 48%;
}
.filter-outercontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}
