.form-range {
    font-size: 13px;
    font-weight: bold;

    .form-range-wrapper {
        display: flex;
        align-items: center;
    }   

    .form-range-line {
        flex-grow: 2;
        -webkit-appearance: none;
        background-color: var(--shlk-digital-alfa);
        outline: none;
        height: 7px;
        border-radius: var(--shlk-border-radius);

        &::-webkit-slider-thumb {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background: var(--shlk-corporate);
            cursor: pointer;
            -webkit-appearance: none;
        }
    }

    .form-range-value {
        margin: 0;
        width: 25px;
        text-align: center;
    }
}