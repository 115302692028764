
.shlk-page {

    &.shlk-page--error {
        background: none;
        background-size: cover;
        height: 100vh;
    }

    .shlk-page-content {
        box-sizing: border-box;
        height: inherit;
        padding: 15px;
    }

    &.shlk-page--map .shlk-page-content {
        position: absolute;
        top: 55px;
        left: 0;
        right: 0;
        bottom: 0;

        max-width: none;
        padding: 0;
    }
    
}
/* Disable body scroll when popup is open */
.no-scroll {
    overflow: hidden;
  }
  
  /* Overlay that covers the entire screen */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dim background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup modal styling */
  .popup-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh; /* Make the modal height dynamic */
    overflow: hidden; /* Prevent modal from overflowing */
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  /* Close button styling */
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Popup content that can be scrolled */
  .popup-content {
    overflow-y: auto; /* Make the content scrollable */
    flex-grow: 1; /* Allow content to take remaining space */
    margin-top: 20px;
    padding-right: 10px; /* Add padding to avoid scrollbar covering content */
  }
  
  /* Prevent body scrolling when popup is active */
  body.no-scroll {
    overflow: hidden;
  }
  

@media screen and (max-width: 767px) {
    .shlk-page-content{
        width: 100% !important;
    }
  }