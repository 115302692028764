

.shlk-admin-trackers {
    .admin-trackers-change {
        background-color: var(--shlk-corporate);
        color: var(--shlk-white);
        border-radius: var(--shlk-border-radius);
        margin-top: 20px;
        display: inline-block;
        padding: 10px;
    }
}