.tags-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    overflow-x: auto;
    gap: 5px;
    scrollbar-width: thin;
    padding-bottom: 10px !important;
    

    &::-webkit-scrollbar {
        display: none;
    }

    .tags-tag {
        display: flex;
        text-align: center;
        align-items: center;
        border-radius: var(--shlk-border-radius);
        padding: 4px 6px;
        color: white;
        white-space: nowrap;

        .tag-icon {
            cursor: pointer;
            margin-right: 2px;
        }
    }
}