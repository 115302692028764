.permissions-option {
    .option-box {
        height: 50px;
        border-radius: var(--shlk-border-radius);
        border: 1px solid var(--shlk-marine);
        display: flex;
        align-items: center;
        justify-content: center;

        .option-icon {
            width: 25px;
            height: 25px;
        }
    }
    .option-caption {
        text-align: center;
        margin: 0;
        text-transform: capitalize;
    }

    &__GRANTED {
        .option-box { 
            background-color: var(--shlk-digital);
        }
    }

    &__DENIED {
        .option-box { 
            background-color: var(--shlk-red);
        }  
    }

    &__DISABLED {
        .option-box { 
            background-color: #CCC;
        }   
    }
}