
.shlk-marker-wrapper {
    position : relative;

    &.shlk-marker__open {
        z-index: 2;
    }
}

.shlk-marker {
    position: absolute;
    transform: translate(-50%, -50%);
}

.shlk-marker-popup {
    position : absolute; 
    background-color : var(--shlk-white);
    bottom: 25px;
    left: 0px;
    padding: 10px;
    border-radius: var(--shlk-border-radius);
    z-index: 1;
    width: max-content;
    border-bottom-left-radius: 0;
    max-width: 250px;

    box-shadow: 0px 0px 4px 0px black;

    .marker-popup {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-field {
            margin:0;

            &.icon {
                display: flex;

                .marker-popup-icon {
                    color: var(--shlk-corporate);
                    margin-right: 3px;
                }
            }
        }
    }
}

.shlk-marker-close {
    width: 20px;
    height: 20px;
    fill: var(--shlk-white);
    display: inline-block;
    vertical-align: bottom;
    position: absolute;
    top: -7.5px;
    right: -7.5px;
    border-radius: 50%;
    background-color: var(--shlk-corporate);;

    > * {
        pointer-events: none;
    }

    &:before {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
        cursor: default;
    }
}

.shlk-marker-triangle {
    position: absolute;
    bottom: -14px;
    left: 0;
    height: 15px;
    width: 15px;

    &::after {
        content: "";
        position: absolute;
        top: -4px;
        left: 0;
        right: -30px;
        height: 5px;
        background-color: var(--shlk-white);
    }

    svg {
        fill: var(--shlk-white);
        filter: drop-shadow(0px 0px 2px black);
    }
}