.marker-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);

    .marker-text-title {
        margin: 0;
        font-size: 12px;
    }

    .marker-text-pin {
        height: 32px;
        width: 32px;
        fill: var(--shlk-marine);
    }
}