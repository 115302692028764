.cc-header {
    display: flex;
    align-items: center;
    color: var(--shlk-corporate);

    h1 {
        flex-grow: 2;
        margin: 0;
    }
    
    margin-top: 20px;
    margin-bottom: 20px;

    .cc-header-icon {
        width: 30px;
        height: 30px;
        fill: var(--shlk-marine);
        margin-right: 10px;
    }
}