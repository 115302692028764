.shlk-shipments {
  position: relative;

  .shlk-title {
    color: var(--shlk-marine);
    margin: 0px;
    display: flex;
    align-items: center;

    .shlk-title__icon {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .shlk-shipment__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;

    .shipment-buttons__group {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .shlk-button {
        display: flex;
        margin-right: 10px;
        align-items: center;
        // margin-top: 10px;

        &.tags-manager {
          padding: 5px;

          span {
            margin-right: 5px;
          }

          .tags-manager__icon {
            margin-right: 5px;
            height: 18px;
            width: 20px;
            fill: var(--shlk-white);
          }
        }
      }
    }
  }

  .shlk-filter__container {
    .filter-inputs {
      &__ongoing,
      &__completed {
        display: none;
      }
    }
  }

  .shlk-shipments__container {
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    overflow-x: auto;
    max-width: 100%;
    // padding: 0 0 15px 5px;
    margin-top: 10px;

    .shlk-title__icon {
      position: absolute;
      right: 0;
      top: 50%;
      background-color: var(--shlk-corporate);
      border-radius: 24px;
      padding: 4px;
      fill: var(--shlk-white);
      height: 36px;
      width: 36px;
    }
  }
}

@media screen and (max-width: 767px) {
  .shlk-shipments {
    .shlk-shipments__container {
      overflow-x: unset !important;
      padding: 0px !important;
    }
  }
}
