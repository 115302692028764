.permissions-table {
    padding: 15px 0;
    
    .table-roles {
        .table-roles-content {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            grid-gap: 10px;
        }

        .form-selectable {
            display: inline-flex;
            padding-right: 15px;
        }

        .table-roles-title {
            margin-top: 0px;
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--shlk-marine);
        }

        .form-selectable-check {
            height: 30px;
            width: 30px;
        }
    }

    .table-dropdown {
        display: flex;
        align-items: center;
        margin-top: 15px;
        border-bottom: 1px solid black;
    justify-content: center;

        p {
            margin: 0;
            font-size: 12px;
            font-weight: bold;
            line-height: 30px;
        }

        &-icon {
            width: 30px;
            height: 30px;
            fill: var(--shlk-marine)
        }
    }

    .table-content {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 10px;

        border: 1px solid var(--shlk-marine);
        border-radius: var(--shlk-border-radius);
        padding: 15px;
        background-color: #DDD;
    }
}