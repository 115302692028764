

.shlk-admin-data {
    h1 {
        margin: 0;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--shlk-marine);
    }

    h1, h3, h4 {
        color: var(--shlk-marine);
    }

    h3, h4, p {
        margin: 0;
    }
    
    .data-group {
        margin-bottom: 15px;
        border-bottom: 1px solid var(--shlk-marine);
        padding-bottom: 15px;
        
        h4 {
            margin-bottom: 5px;
        }

        h3 {
            margin-bottom: 15px;            
        }

        &-companies, &-total {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            > div {
                white-space: nowrap;
            }
        }
    }

    .data-company {
        margin-bottom: 10px;

        .data-0 {
            color: red
        }
        .data-1 {
            color: green
        }
    }
}