.marker-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--shlk-corporate);
    display: inline-block;
    line-height: 300%;
    text-align: center;

    &-tower {
        background-color: #CADC9B;
    }
    &-wifi {
        background-color: #F2E058;
    }
    &-vessel {
        background-color: #9E8DDF;
    }
    &-tracker {
        background-color: #ADF1F5;
    }
    &-cluster {
        background-color: white;
    }
    &-na {
        background-color: #E8827D;
    }

    .marker-type-marker & {
        height: 7px;
        width: 7px;
        border: 1px solid var(--shlk-marine);
    }
}

.marker-dot-hitbox {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}