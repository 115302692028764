@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.shlk-trackers {
    .trackers-header {
        display: flex;
        align-items: center;

        .box-title {
            display: inline-block;
            margin-bottom: 5px;
        }

        .filter-icon {
            display: inline-block;
            margin-left: 5px;
        }
    }

    .tracker-option {
        .option-content {
            margin-right: 0;
        }

        .box-option__disabled {
            .option-icon {
                -webkit-animation: rotating 1s linear infinite;  
            }
        }

        .boxtracker-info {
            display: flex;
            flex-direction: column;
            gap: 4px 0;

            .tracker-title {
                display: flex;
                align-items: center;
                gap: 0 5px;
                font-size: 15px;
                font-weight: bold;

                .title-icon {
                    color: var(--shlk-corporate);
                }
            }

            .tracker-field {
                font-size: 14px;
            }

            .tracker-action {
                display: flex;
                align-items: center;
                gap: 0 8px;

                .tracker-button {
                    display: flex;
                    align-items: center;
                    border-radius: var(--shlk-border-radius);
                    padding: 4px;
                    border-width: 1px;
                    font-weight: unset;

                    &.selection {
                        &.selected {
                            background-color: var(--shlk-corporate);
                            color: white;
                        }

                        &.unselected {
                            color: transparent;
                        }
                    }

                    &.shipment {
                        padding: 3px 8px;
                    }
                }

                .tracker-icon {
                    height: 18px;
                    width: 18px;
                    fill: white;
                }
            }
        }
    }

    .trackers-warning {
        font-size: 15px;
        margin-top: 10px;
    }
}
