.shlk-button {
    text-decoration: none;
    border: 2px solid var(--shlk-corporate);
    border-radius: 5px;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    color: var(--shlk-corporate);
    background-color: transparent;
    cursor: pointer;

    &.shlk-button__solid {
        color: white;
        background-color: #19575C;
    }

    &.shlk-button__alert {
        color: white;
        border: 0;
        background-color: var(--shlk-red);
    }

    &.shlk-button__outline {
        color: var(--shlk-corporate);
    }

    &.shlk-button__none {
        border: 0;
    }

    &[disabled], &.shlk-disabled {
        opacity: 0.5;
        cursor: unset;
        pointer-events: none;
    }


    &.icon {
        display: flex;
        align-items: center;
        gap: 0 5px;
    }
}