.form-settings {
    margin-right: 5px;
    padding-bottom: 15px;

    .settings-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .settings-group {
        display: flex;
        gap: 10px;

        .settings-input {
            flex-grow: 1;
            display: inline-block;
        }
    }
}

.form-settings-create {
    display: flex;
    margin-top: 5px;
}

