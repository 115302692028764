.intermodal-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: azure;
  background-color: var(--shlk-marine);
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  margin: 5px;

      //Reduce margin for h2
      h2 {
        margin: 0 0 10px 0;
      }
  

  .node-row, .distance-row {
    width: 100%;
    background-color: var(--shlk-marine);
    color: azure;
    margin: 2px 0;
    box-sizing: border-box;
    text-align: left;
    
  }

  .distance-row {
    padding-left: 40px;
  }

  .bold-text {
    font-weight: bold;
  }

  .icon {
    margin-left: 10px;
  }
}
