.shlk-page-shipments {
  .shlk-filter__container {
    margin-bottom: 15px;

    .shlk-filter__search {
      display: inline-block;
      margin-right: 15px;
    }

    .shlk-filter__tags {
      display: inline-block;
      margin-right: 15px;

      .input-element {
        margin: 0;
      }
    }

    .shlk-filter__date {
      display: inline-block;
      margin-right: 10px;
    }

    .shlk-filter__button {
      width: 80px;
    }

    .tags-container {
      flex-wrap: wrap;

      .tags-tag {
        margin: 10px 10px 10px 0;
      }
    }
  }

  .shlk-shipments__container {
    position: relative; /* Ensure the container is positioned relatively */
    display: flex;
    flex-direction: column;
    height: 100%; /* Full height */
    overflow-y: auto;

    .shipment-card__container {
      display: inline-block;
    }
  }
}
.shipment-cards {
  flex: 1; /* Allow cards to take up remaining space */
  overflow-y: auto; /* Enable scrolling for cards */
}

.box-button-menu {
  position: relative;
  // background-color: white;
}

.box-button-back {
  color: var(--shlk-transparent);
  background-color: var(--shlk-marine);
  border-radius: 50%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  gap: 1px 0;
  font-weight: bold;
}

.back {
  font-size: 12px;

  gap: 1px 0;
  font-weight: bold;

}

.box-button {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 10px;

    &.back {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      margin-bottom: 12px;
      gap: 1px 0;
      font-weight: bold;

      .box-button-back {
        color: var(--shlk-transparent);
        background-color: var(--shlk-marine);
        border-radius: 50%;
        padding: 4px;
        
      }
    }
}


// .pagination {
//   position: sticky; /* Sticky positioning to keep it at the bottom */
//   bottom: 0; /* Align pagination to the bottom */
//   left: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   background-color: white;
//   padding: 10px 0;
//   box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); /* Optional for better visibility */
//   z-index: 100; /* Ensure it stays above content */
// }

// .pagination button {
//   border: none;
//   background: none;
//   cursor: pointer;
//   padding: 5px 10px;
//   font-size: 16px;
// }

// .pagination .active {
//   font-weight: bold;
//   color: blue;
// }

// .pagination {
//   position: sticky;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   background-color: white;
//   padding: 10px 0;
//   box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
//   z-index: 100;
// }

// .pagination button {
//   border: none;
//   background: none;
//   cursor: pointer;
//   padding: 10px 15px;
//   margin: 0 5px;
//   font-size: 14px;
//   border-radius: 10px; /* Adds a slight border-radius to make the edges less sharp */
//   background-color: #f0f0f0; /* Default background for buttons */
//   color: #19575c; /* Default text color */
//   transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
// }

// .pagination button:hover {
//   background-color: #ddd; /* Change color on hover */
// }

// .pagination .active {
//   background-color: #19575c;
//   color: #fff;; /* Dark background for active page */
//   color: white; /* White text for active page */
//   font-weight: bold;
// }

.pagination {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 10px 0;
  margin: -8px;
  z-index: 100;
  // top:80vh
}

.pagination button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0 5px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #f0f0f0;
  color: var(--shlk-marine);
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed; /* Makes the disabled buttons unclickable */
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination .active {
  background-color: var(--shlk-marine);
  color: white;
  font-weight: bold;
}

.pagination span {
  align-content: space-around;
  margin: 0 5px;
  font-size: 14px;
  color: #666;
}

.Pagination-icon{
  margin: 0px !important;
  padding: 0px !important;
}


// .pagination-wrapper {
//   position: fixed; /* You can also use sticky if it fits better */
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-color: white;
//   padding: 10px 0;
//   display: flex;
//   justify-content: center;
//   z-index: 100;
// }

// .pagination button {
//   border: none;
//   background: none;
//   cursor: pointer;
//   padding: 10px 15px;
//   margin: 0 5px;
//   font-size: 14px;
//   border-radius: 10px;
//   background-color: #f0f0f0;
//   color: var(--shlk-marine);
//   transition: background-color 0.3s, color 0.3s;
// }

// .pagination button:disabled {
//   background-color: #ddd;
//   cursor: not-allowed; /* Makes the disabled buttons unclickable */
// }

// .pagination button:hover {
//   background-color: #ddd;
// }

// .pagination .active {
//   background-color: var(--shlk-marine);
//   color: white;
//   font-weight: bold;
// }

.buttonss{

 
}