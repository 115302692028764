.report-events {
    margin-top: 30px;

    &-title {
        margin: 0 0 10px 0;
    }

    .report-event {
        &-field {
            margin: 0;
            margin-right: 15px;
        }

        &-location {
            text-align: center;
        }

        &-date {
            color: #888;
        }

        &-icon {
            text-align: center;
        }
        &__alarmevent {
            color: var(--shlk-red);
        }
        &__infoevent {
            color: var(--shlk-digital);
        }
    }
    
}