.shipment-tags {
    display: flex;
    flex-direction: column;
    height: 100%;

    .shipment-tags__data {
        flex-grow: 1;

        .tags-container {
            flex-wrap: wrap;

            .tags-tag {
                margin: 5px 5px 5px 0;
            }
        }
    }

    .shipment-tags__action {
        display: flex;
        flex-direction: column;
        gap: 5px 0;

        .form-input {
            width: 100%;
        }

        .input-element {
            margin: 0;
        }

        .tags-btn {
            border-width: 1px;
            border-radius: var(--shlk-border-radius);
            font-size: 12px;
            margin-top: 10px;
        }
    }

    .shipment-tags-empty {
        margin: 0 0 10px 0;
        font-size: 14px;
        color: #333;
    }
}