.shlk-status__event {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 140px;
    min-width: 20%;
    
    .event-icon {
        color: white;
        background-color: var(--shlk-corporate);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
    }
    .red-icon{
        color: white;
        background-color: #C11C1C;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
    }

    .event-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: capitalize;

        .event-count {
            font-size: 30px;
            font-weight: bold;
        }
    }

    
}

.mobile-event{
    display: flex;
    flex-direction: row !important;
    min-height: 0px !important;
    min-width: 0px !important;
    .event-count {
        font-size: 20px !important;
        font-weight: bold;
        margin-right: 2px !important;
    }
}