.report-status {
    display: flex;
    justify-content: center;
    font-size: 14px;
    gap: 25px;

    p:not(:first-of-type) {
        margin: 0;
    }
    h4 {
        margin: 20px 0 0 0;
    } 
}