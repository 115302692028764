.shlk-page-raw {
    display: flex;
    flex-direction: column;
    padding: 20px 40px 40px 40px;
    box-sizing: border-box;
    height: 100%;

    .shlk-title__container {
        .shlk-title {
            margin: 8px 0 2px 0;
            font-size: 24px;
        }
    }

    .shlk-raw-download__container {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;

        .download-title {
            font-weight: bold;
            align-self: center;
        }

        .download-button {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .download-icon {
                fill: white;
                height: 16px;
                width: 16px;
                margin-left: 5px;
                transform: rotate(270deg);
            }
        }
    }
}