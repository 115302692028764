
@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.shipment-events {
    padding-bottom: 15px;
    max-width: 15rem;

    .event-box {
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #CCC;
        display: flex;
        align-items: center;

        .event-field {
            margin: 0;
            font-size: 12px;
            flex-grow: 2;
        }

        .event-title {
            font-size: 14px;  
        }
    }

    .event-icon {
        color: var(--shlk-marine);
        margin-right: 5px;
    }

    .event-ack-false .event-icon {
        color: var(--shlk-red);
    }

    .event-show {
        display: flex;
        align-items: center;

        .event-show-caption {
            font-size: 10px;
        }

        .event-show-icon {
            width: 20px;
            height: 20px;
        }
    }

    
}

.event-selected {
    border-left: 4px solid var(--shlk-corporate);
    background-color: var(--shlk-corporate-complementary);
    
  }
  