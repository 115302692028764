.form-info {
    display: flex;
    cursor: default;
    position: relative;
    
    &__text {
        position: absolute;
        text-transform: none;
        border: 1px solid var(--shlk-corporate);
        border-radius: var(--shlk-border-radius);
        left: 25px;
        top: 10px;
        padding: 5px;
        background-color: white;
        font-size: 14px;
        min-width: 120px;
        z-index: 1;
    }
    
    &__icon {
        margin: 0 10px;
        cursor: pointer;
    }
}