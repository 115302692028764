.shlk-shipment-report {
  .shlk-page-content {
    // overflow-x: auto;
  }

  .report-table {
    width: 100%;
  }
  @keyframes slideUp {
    from {
      transform: translate3d(0, 110%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 110%, 0);
    }
  }

  @keyframes turnUpside {
    from {
      transform: rotateZ(180deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }

  @keyframes blackingOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }

  .shlk-content-popup,
  .shlk-cp-card,
  .shlk-cp-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .shlk-content-popup {
    z-index: 10;
  }

  .shlk-cp-card {
    animation-duration: 1s;
    animation-name: slideUp;
    transform: translate3d(0, 0, 0);

    background-color: var(--shlk-transparent);
    backdrop-filter: blur(6px);
    max-height: 85vh;
    height: 100%;

    .shlk-desktop & {
      top: auto;
      bottom: 0;
      height: 100%;
      max-height: 90%;
    }
    .shlk-mobile & {
      top: auto;
    }
  }

  .shlk-cp-background {
    background-color: #000;
    transition: opacity 1s cubic-bezier(0.68, 0.07, 0.9, 1);
    animation-duration: 1s;
    animation-name: blackingOut;
    opacity: 0.7;
  }

  .shlk-cp-closing {
    .shlk-cp-card {
      animation-name: slideDown;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
    .shlk-cp-background {
      opacity: 0;
    }
  }

  .shlk-cp-close {
    color: var(--shlk-transparent);
    transform: rotateZ(180deg);

    animation-name: turnUpside;
    animation-duration: 0.2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    pointer-events: auto;

    &-wrapper {
      background-color: var(--shlk-corporate);
      display: inline-flex;
      border-radius: 100%;
    }
  }

  .shlk-cp-header {
    position: absolute;
    top: -20px;
    text-align: center;
    width: 100%;
    display: inline-block;
    z-index: 10;
    pointer-events: none;
  }

  .shlk-cp-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    border-top: 2px solid var(--shlk-marine);
  }

  .marker-live-tracker__active {
    animation: none;
    box-shadow: none;
    border: 1px solid var(--shlk-corporate);
  }

  h2,
  h3,
  h4 {
    color: var(--shlk-corporate);
  }
  .add-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    .shlk-mobile & {
      display: grid;
    }

    .shlk-button {
      flex-grow: 1;
      width: 100%;
    }
  }
  .add-inputs {
    margin-top: 20px;
    display: flex;
    gap: 10px;

    .shlk-mobile & {
      display: grid;
    }

    .add-input-invalid {
      border: 2px solid var(--shlk-red);

      &:focus {
        outline: 1px solid var(--shlk-red);
      }
    }

    .form-label {
      margin-right: 0;
      flex-grow: 1;
      width: 100%;

      .form-label__name {
        display: block;
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        color: var(--shlk-marine);
      }
      .form-input {
        width: 100%;
      }
    }
  }

  .report-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    padding: 0 20px;
    align-items: center;

    &-header {
      text-align: center;
      margin: 30px 0 0 0;
    }

    &-left {
      flex-grow: 1;

      .shlk-desktop & {
        max-width: 50%;
        padding-right: 30px;
        margin-right: 30px;
        box-sizing: border-box;

        &--border {
          border-right: 1px solid var(--shlk-corporate);
        }
      }

      .shlk-mobile & {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &--border {
          border-bottom: 1px solid var(--shlk-corporate);
        }
      }
    }

    &-right {
      flex-grow: 1;

      .shlk-mobile & {
        width: 100%;
      }
    }
  }

  .shlk-map {
    border-radius: 36px;
    overflow: hidden;
    width: 100%;
    height: 350px;
  }

  @media print {
    .report-hide,
    .shlk-header {
      display: none;
    }

    .report-unbreakable {
      break-inside: avoid;
    }
  }
}

.dropdown-select {
  background-color: var(--shlk-digital-alfa);
  border-radius: var(--shlk-border-radius);
  border: none;
  color: var(--shlk-marine);
  height: 30px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 12px;
  width: 10%;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,%3Csvg fill='var%28--shlk-marine%29' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");

  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
}
