.marker-location {
  text-align: center;
  color: var(--shlk-marine);
}

.marker-location.origin {
  color: #9400ff;
}

.marker-location.destination {
  color: #260a74;
}

.span-text {
  font-weight: bold;
  font-size: 15px;
  text-shadow: 2px 2px white;
}
