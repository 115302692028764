.report-qr {
    text-align: center;
    padding-top: 10px;   

    .report-qr-caption {
        font-size: 12px;
        color: #999;
        margin: 0;
        margin-top: -15px;

        a {
            color: var(--shlk-corporate)
        }
    }
}