
.shipment-trackers {
    padding-bottom: 15px;

    .trackers-box {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--shlk-grey);
    }

    .tracker-info {

        .tags-container {
            margin: 0;
            flex-wrap: wrap;
        }
    }

    .tracker-options {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 0 16px;

        .tracker-option, .tracker-raw {
            display: flex;
            flex-direction: column-reverse;
            gap: 2px 0;
            align-items: center;

            .label {
                margin: 0;
                margin-bottom: 3px;
                font-size: 10px;
                font-weight: bold;
            }
        }

        .tracker-checkbox {
            .tracker-checkbox-icon {
                height: 25px;
                width: 25px;
                fill: var(--shlk-corporate);
            }

        }

        .tracker-raw {
            .tracker-raw-icon {
                height: 25px;
                width: 25px;
                padding: 5px;
                box-sizing: border-box;
                margin: auto;
                background-color: var(--shlk-corporate);
                border-radius: 50%;

                svg {
                    fill: white;
                    height: 15px;
                    height: 15px;
                    padding-bottom: 3px;
                }

            }
        }
    }
}

