.shlk-header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  align-items: center;
  background-color: #19575c;
  box-shadow: 0px -12px 12px 12px #000000;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  box-sizing: border-box;
  height: 55px;
  color: white;

  .header-nav {
    display: inline-flex;
    flex-wrap: wrap;
    color: #5bffe1 !important;

    .header-logo {
      &-link {
        height: 100%;
      }

      &-image {
        width: 165px;
      }
    }
  }

  .header-user {
    display: inline-flex;
    gap: 0 20px;
    align-items: center;
    justify-content: flex-end;

    .header-wrapper {
      display: flex;
      align-items: center;
      text-decoration: none;
      // font-weight: bold;
      font-size: 20px;
      margin: 0px;
      margin-right: 5px;
      font-family: "Roboto Condensed";
      letter-spacing: 3px;

      .header-caption {
        border: 2px solid;
        border-radius: var(--shlk-border-radius);
        padding: 5px 10px;
        margin-right: 5px;
        font-weight: bold;
        font-size: 22px;
      }
    }
  }
}

.box-menu-icon {
  // margin-right: 15px;
}
.active {
  color: #5bffe1;
}

.header-wrapper.active {
  color: #59f9dc;
  text-decoration: underline !important;
}

.header-wrapper:hover {
  color: #59f9dc; /* Color on hover */
}

.hamburger-menu {
  position: absolute;
  top: 50px;
  background: #19575c;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50vh;
  width: 20vh;
  right: 0%;
  align-items: center;
}

.hamburger-menu-button{
  background: transparent;
    border: 0px;
    color: white;
}