.report-history {
    table {
        margin: auto;
        width: 100%;
        text-align: center;
    }

    thead td{
        padding-top: 20px;
    }

    td {
        font-size: 14px;
    }

    .report-history-distance {
        font-size: 12px;
        color: #999;
    }
}