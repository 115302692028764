.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 10px 0;

    .dropdown-title {
        display: flex;
        gap: 0 8px;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
    }

    .dropdown-icon {
        cursor: pointer;
        height: 20px;
        width: 20px;
        fill: var(--shlk-marine);
        background-color: var(--shlk-digital);
        border-radius: 50px;
    }
}