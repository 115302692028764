.marker-tower {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: none;

    & .shlk-marker {
        transform: none;
    }

    &-area {
        position: absolute;
        transition: width 0.5s, height 0.5s;
        display: inline-block;
        background-color: rgba(45, 153, 247, 0.2);
        border: 1px solid var(--shlk-digital);
        text-align: center;
        border-radius: 50%;
        pointer-events: none;
    }
}