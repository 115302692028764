
.shlk-trackers {
    .filter-box {
        display: flex;
        flex-direction: column;
        gap: 10px 0;

        .box-field {
            display: flex;
            align-items: center;
            gap: 4px 8px;

            > * {
                flex-grow: 1;
            }

            & .no-fill {
                flex-grow: unset;
            }

            &.wrap {
                flex-wrap: wrap;
            }

            .field-label {
                font-size: 14px;
                font-weight: bold;
            }

            .form-range {
                .form-range-line {
                    width: 50px;
                }
            }
        }

        .shlk-filter__tags {
            .input-element {
                margin-left: 0;
                border-radius: var(--shlk-border-radius);
                width: 100%;
            }
        }

        .tags-container {
            flex-wrap: wrap;
        }
    }
}
