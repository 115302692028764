.shlk-home-auth {
  .shlk-widget {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
  }

  .shlk-widget-double {
    display: inline-block;
    width: 100%;
    height: auto;
    margin-bottom: 15px;

    .shlk-widget {
      height: 100%;
      margin-right: 0;
    }
  }
}

.shlk-desktop .shlk-home-auth .shlk-widget-double {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  .shlk-widget {
    width: 50%;
    flex: 1;

    &:first-child {
      margin-right: 7.5px;
    }

    &:last-child {
      margin-left: 7.5px;
    }
  }
}
