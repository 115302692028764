.tags-ref-members {
    .references-title {
        display: flex;
        gap: 20px;

        h3 {
            margin: 0;
        }
    }

    .references-share {
        display: flex;
        align-items: center;
        padding: 2px 5px;
        font-size: 12px;
        border-radius: var(--shlk-border-radius);
    }
}