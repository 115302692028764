footer {
    height: 50px;

    .footer-hint {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        background-color: var(--shlk-corporate);
        color: white;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        border-radius: var(--shlk-border-radius);

        &__link {
            color: var(--shlk-digital);
        }
    }    
}