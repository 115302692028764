.shlk-fleet-loaded {
    .fleet-loaded-shipment {
        display: flex;
        align-items: center;
        padding: 10px 0;
        width: 100%;

        .loaded-info {
            flex-grow: 1;
        }

        p {
            margin: 0;
        }
    }

    .fleet-info {
        width: 100%;
        border-bottom: none;
    }

    .fleet-list__element {
        flex-direction: column;
        border-bottom: 1px solid var(--shlk-grey);
    }
}