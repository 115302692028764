.shlk-selectable-list {
    border: 1px solid var(--shlk-marine);
    border-radius: var(--shlk-border-radius);
    font-size: 14px;
    padding: 4px 8px;
    position: relative;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none; // Add -webkit-user-select to support Safari and Safari on iOS.
    background-color: white;
    cursor: pointer;

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:after {
        content:'';
        height: 0;
        position: absolute;
        width: 0;
        border: 6px solid transparent;
        border-top-color: var(--shlk-marine);
        top: 50%;
        right: 10px;
        margin-top: -3px;
    }

    &.active:after {
        border-bottom-color: var(--shlk-marine);
        border-top-color: transparent;
        margin-top: -9px;
    }


    &.active .list-container {
        opacity: 1; /* display the dropdown */
        pointer-events: auto; /* make sure that the user still can select checkboxes */
        overflow: hidden;
    }

    .list-container {
        background-color: white;
        z-index: 1;
        position: absolute;
        top: 100%; /* align the dropdown right below the dropdown text */
        border: inherit;
        border-top: none;
        left: -1px; /* align the dropdown to the left */
        right: -1px; /* align the dropdown to the right */
        opacity: 0; /* hide the dropdown */ 
        pointer-events: none; /* avoid mouse click events inside the dropdown */
        border-bottom-left-radius: var(--shlk-border-radius);
        border-bottom-right-radius: var(--shlk-border-radius);
        cursor: pointer;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: auto;
            max-height: 150px;

            li {

                label {
                    display: flex;
                    align-items: center;
                    gap: 0 4px;
                    border-top: 1px solid var(--shlk-marine);
                    padding: 4px 6px;
                    transition: all 0.2s ease-out;
    
                    &:hover {
                        background-color: var(--shlk-digital);
                        color: var(--shlk-marine);;
                    }
                }
            }
        }
    }
}