
.actions-popup {
    padding: 0 15px;
    max-width: 700px;
    margin: auto;

    .actions-group {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 12px 0;

        .action-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .action-options {
                display: flex;
                align-content: center;
                gap: 0 5px;
            }
        }

        .action-title {
            margin: 0;
        }

        .table-component  {
            margin: 0;
        }

        &.actions-options {
            &-wrapper {
                display: flex;
                gap: 15px;
    
                .actions-option {
                    min-width: 30%;
                }
            }
    
            .actions-option {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }
    
            &.form-settings  {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;
        
                .settings-input__field {
                    max-width: 100px;
                }
            }
        }
    }


    
}
