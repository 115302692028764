
.tracker-info {
    display: flex;
    flex-direction: column;
    gap: 4px 0;

    .tracker-name {
        font-size: 18px;
        margin: 0;
        margin-bottom: 2px;
    }
    
    > :not(.tracker-name) {
        font-size: 14px;
        display: grid;
        grid-template-columns: 70px 1fr;
        align-items: center;
    }

    b {
        margin-right: 5px;
    }

    .tracker-tags {
        display: inline-block;
    }

    .tags-container {
        display: inline-flex;
        margin-top: 5px;
    }
}