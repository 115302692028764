.dropdown-container{

    margin-top: 10px;
    display: block;
    .new-input-label {
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
    }
    
    .new-dropdown-value {
        background-color: var(--shlk-digital-alfa);
        border-radius: var(--shlk-border-radius);
        border: none;
        color: var(--shlk-marine);
        height: 30px;
        padding: 5px 10px;
        box-sizing: border-box;
        font-size: 12px;
        width: 100%;
    
        &:focus {
            outline: none;
            // You can add additional styles for the focus state if needed
        }
    
        // Adjust this as necessary for your dropdown design
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    
        // Optional: Add an icon for the dropdown arrow
        background-image: url('data:image/svg+xml;utf8,<svg fill="var(--shlk-marine)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 15px;
    }
}
