.shipment-info {
  .shipment-info__title {
    .info-title {
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 5px;
      padding-right: 40px;
      box-sizing: border-box;
      overflow-x: auto;
    }

    .info-status {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: bold;

      &.info-status__ongoing span {
        color: var(--shlk-green);
      }

      &.info-status__completed span {
        color: #ccc;
      }

      &.info-status__canceled span {
        color: red;
      }
    }
  }

  .shipment-info__data {
    margin-bottom: 5px;

    .info-field {
      font-size: 12px;
      margin: 0;
      margin-bottom: 5px;

      b {
        font-weight: bold;
      }
    }
  }

  .info-btn-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
}
.info-btn-wrapper {
  width: 100px;
}

.shipment-card__bar.shipment-card__field.right.info-btn-wrapper > :first-child {
  background-color: white;
  color: #b20001;
  border: 2px solid #b20001;
}
