.fleet-list__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;

    .fleet-info {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin-right: 5px;
        height: 45px;

        .fleet-info__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--shlk-marine);
            font-weight: bold;
            margin-right: 15px;
            font-size: 15px;
            height: 22px;
            width: 22px;
            background-color: var(--shlk-corporate-complementary);
            border-radius: 50%;
        }

        .fleet-info__text {
            .vessel-info__name {
                color: var(--shlk-corporate);
            }

            .vessel-info__mmsi {
                font-size: 15px;
                letter-spacing: 0.5px;
            }
        }
    }

    .fleet-action {
        .fleet-action__icon {
            width: 30px;
            height: 30px;
            fill: var(--shlk-digital);
        }

        &.active .fleet-action__icon {
            fill: var(--shlk-corporate);
        }
    }
}