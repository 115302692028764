.company-card {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
  color: initial;

  h3 {
      margin: 0;
      font-size: 18px;
  }

  &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  &.selected {
      color: #fff;

      &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background-color: var(--shlk-marine);
          animation: slide 0.25s forwards;
      }

      h3 {
          position: relative;

          &:after {
              content: attr(data-content);
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              animation: slide 0.25s forwards;
          }
      }

      &:after {
        content: "→";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
      }

  }
}

@keyframes slide {
  to {
      width: 100%;
  }
}