.shlk-tags {
    .tags-title.tag {
        padding: 4px 10px;
        padding-bottom: 3px;
        border-radius: var(--shlk-border-radius);
        color: white;
       // min-height: 38px;
    }

    .tags-toggle-btn {
        display: flex;
    }

    .tags-buttons.tag {
        input[name=tag-name] {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: 120px;
        }
        input[name=tag-color] {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .table-component {
        td.ongoing {
            color: var(--shlk-green);
        }

        td.completed {
            color: grey;
        }
    }
}