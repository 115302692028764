@keyframes pulse {
    0% { transform: scale(1, 1) }
    50% { transform: scale(1.2, 1.2) }
    100% { transform: scale(1, 1) }
}

.marker-live-vessel-container {

    &.active {
        animation: pulse 2s infinite linear;

        .marker-live-vessel-text {
            background-color: white;
            color: black;
        }
    }

    .marker-live-vessel {
        position: relative;

        &-text {
            text-align: center;
            font-weight: bold;
            border: 2px solid var(--shlk-corporate);
            border-radius: 50%;
            background-color: var(--shlk-corporate);
            color: white;
            font-size: 15px;
            height: 18px;
            width: 18px;
            position: relative;
            z-index: 1;
        }

        &-icon {
            height: 28px;
            width: 28px;
            fill: var(--shlk-corporate);
            position: absolute;
            top: 0px;
            left: 11px;
            transform: translate(-50%, -50%);
        }

        &-fallback {
            height: 20px;
            width: 20px;

            &.marker-vessel__moving {
                fill: var(--shlk-green);
            }
            &.marker-vessel__stopped {
                fill: #777;
            }
        }
    }
}