.box-option {
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    border-bottom: 1px solid var(--shlk-grey);
    margin: 0 -15px;

    .option-content {
        margin-right: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        flex-grow: 1;
    }

    .option-icon {
        color: var(--shlk-corporate);
    }

    &__selected {
        border-left: 4px solid var(--shlk-corporate);
        background-color: var(--shlk-corporate-complementary);
        padding-left: 11px;

        .option-icon {
            color: var(--shlk-marine);
        }
    }

    &__disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}