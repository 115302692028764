.form-input-calendar {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .input-calendar-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        background-color: var(--shlk-corporate-complementary);
        font-weight: 500;
        border-radius: 8px;
        overflow: hidden;
        height: 36px;
        min-width: 140px;
        text-align: center;

        .input-label {
            flex-grow: 1;
            padding: 0 15px;
        }

        input.input-calendar {
            box-sizing: border-box;
            outline: none;
            background-color: transparent;
            border: 0;
            letter-spacing: 0.5px;
            flex-grow: 1;
            text-align: center;

            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &::-webkit-datetime-edit-text { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-month-field { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-day-field { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-year-field { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-minute-field { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-second-field { color: var(--shlk-corporate); }
            &::-webkit-datetime-edit-hour-field { color: var(--shlk-corporate); }
        }
    }
}