.shipment-card__container {
  display: flex;
  flex-direction: column;
  width: 341px;
  margin: 5px 15px 5px 1px;
  font-family: "Roboto Condensed";
  font-size: 14px;
  color: var(--shlk-marine);
  &.expanded {
    width: auto;
  }
  .shipment-card__box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin-bottom: 6px;
    box-shadow: rgba(61, 95, 160, 0.6) 1px 2px 10px;

    .shipment-card__top {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
      border-bottom: 1px solid #ccc;
      flex-grow: 1;
      align-items: flex-start;

      .shipment-card__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .shipment-card__field {
          margin: 0 0 5px 5px;
          font-weight: 600;
          white-space: nowrap;

          &.left {
            font-weight: bold;
            margin-bottom: 15px;
          }
          &.right {
            font-weight: bold;
            justify-self: end; /* Aligning text to the end (right) */
            margin-bottom: 15px;
          }
          &.address {
            font-weight: bold;
          }

          &.name {
            font-weight: bolder !important;
            display: flex;
            align-items: center;
          }

          &.event-icon-box {
            display: flex;
          }

          &.expanded-line-chart {
            width: 1050px !important;
            height: 300px;
            background-color: var(--shlk-corporate-light);
            padding: 5px 10px;
            border-radius: 4px;
          }
          &.expanded-bar-chart {
            width: 500px !important;
            height: 300px;
            background-color: var(--shlk-corporate-light);
            padding: 5px 10px;
            border-radius: 4px;
          }
          &.expanded-doughnut-chart {
            width: 300px !important;
            height: 300px;
            background-color: var(--shlk-corporate-light);
            padding: 5px 10px;
            border-radius: 4px;
          }
        }

        .shipment-card__tags {
          .tags-container {
            flex-wrap: nowrap;
            .tags-tag {
              margin: 8px 0px 5px 5px;
              font-weight: bold;
            }
          }
        }
      }

      .shipment-card__chevron {
        display: flex;
        align-items: center;
      }
    }

    .shipment-card__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 10px;
      height: 35px;

      .shipment-card__alerts {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        .event-icon__transport {
          padding: 4px;
          background-color: var(--shlk-coral);
          margin: 0 5px;
          border-radius: 50%;
        }
      }

      .event-icon__shipment {
        margin: 0 5px;
        fill: var(--shlk-corporate);

        svg {
          display: block;
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .shipment-card__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2px;

    .shipment-bar {
      flex-grow: 1;
      position: relative;
      border-radius: 20%;
      background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);
      background-color: var(--shlk-corporate-complementary);
    }

    .shipment-bar__progress {
      position: absolute;
      height: 100%;
      border-radius: var(--shlk-border-radius);
      background-color: var(--shlk-corporate);
    }

    .shipment-bar__event {
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: var(--shlk-coral);

      &.ack {
        background-color: #989595;
      }
    }

    .shipment-bar__percentage {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}

.shlk-card {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  border-radius: var(--shlk-border-radius);
  padding: 20px 15px;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: var(--shlk-white);
}

.shipment-card__carbon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--shlk-corporate-light);
  padding: 5px 10px;
  border-radius: 4px;
  width: 170px;
  height: 33px;

  &.warnings {
    color: #c11c1c;
  }

  &.expanded {
    width: auto;
  }

  .dynamic-data {
    color: #2b8e8f;
    font-size: 16px;
    font-weight: bold;
  }

  .static-data {
    color: #8b8b8b;
    font-size: 13px;
    font-weight: bold;
  }
}

.shipment-card__bar.full_width {
  grid-column: 1 / -1; /* Span all columns */
  margin-bottom: 15px;
}

.shipment-bar__point {
  position: absolute;
  width: 9px; /* Adjust size as needed */
  height: 9px; /* Adjust size as needed */
  top: -1.5px;
  background-color: #8b8b8b; /* Adjust color as needed */
  border-radius: 50%; /* Makes the points circular */
  transform: translateX(-50%);
  &.expanded {
    background-color: #8b8b8b;
  }
}
.shipment-container {
  position: relative; /* Container needs to be relative */
}

.shipment-type {
  position: absolute;
  top: -30px; /* Adjust this value as needed to position the shipment type */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #19575c;
  font-weight: bold;

  &.time {
    left: 95% !important;
  }
}

.shipment-bar__point.done {
  background-color: #19575c;
  z-index: 1;
}

.shipment-bar__point.beyond-progress {
  background-color: grey; /* Change the background color as needed */
}

.event-icon-1 {
  color: white;
  background-color: var(--shlk-red);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 10px;
  width: 25px;
  height: 25px;
}

.event-icon-idle-1 {
  color: white;
  background-color: var(--shlk-green);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 8px;
  border-radius: 50%;
  padding: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.shipment-bar__line.hovered {
  background: repeating-linear-gradient(
    90deg,
    blue 0 9px,
    white 0 15px
  ); /* Change to your desired color */
}

.shipment-bar__line {
  width: 100px; /* Adjust the width of the line */
  height: 3px; /* Adjust the height of the line */
  background-color: #8b8b8b; /* Set the color of the line */
  position: absolute;
  background: repeating-linear-gradient(90deg, #8b8b8b 0 9px, white 0 15px);

  &.expanded {
    background-color: #8b8b8b !important;
    background: repeating-linear-gradient(
      90deg,
      #8b8b8b 0 9px,
      white 0 15px
    ) !important;
  }
}

.shipment-progress-box {
}

.shipment-bar__line.done {
  background: #19575c !important;
}

.container-number {
  margin-right: 80px; /* Add spacing between the container number and "show more" */
}

.show-more {
  cursor: pointer; /* Add pointer cursor to indicate interactivity */
  /* Add any other styles you need for the "show more" button */
}

.actual-time-arrival {
  color: #2b8e8f;
}

.estimated-time-arrival {
  color: #c11c1c;
}

.shipment-bar__icon {
  &.expanded {
    position: absolute;
    left: 100%;
    transform: translateX(-50%);
    top: -15px;
    background-color: #19575c;
    border-radius: 50%;
    padding: 5px;
  }
}

.expanded-bar-chart {
  height: 400px;
  background-color: var(--shlk-corporate-light);
  padding: 5px 10px;
  border-radius: 4px;
}
.expanded-doughnut-chart {
  height: 400px;
  background-color: none;
  padding: 0px 10px;
  border-radius: 4px;
}

.container {
  display: flex;
  flex-direction: column;
}

.grid-container {
  display: grid;
  grid-template-columns: 30% 70%; /* Column widths adjusted to 30% and 40% */
  gap: 10px; /* Adjust gap between columns */
}

/* Additional CSS styling as needed */

.underline-opening:after {
  content: "";
  width: 3px;
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.underline-opening:focus:after,
.underline-opening:hover:after {
  width: 100%;
  height: 3px;
}

.underline-opening:before {
  content: "";
  width: 3px;
  height: 3px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.underline-opening:focus:before,
.underline-opening:hover:before {
  width: 100%;
  height: 3px;
}

.image {
  width: 220px;
  height: 220px;
  cursor: pointer;
  border-radius: 5px;
  object-fit: cover;
}

.image-div {
  margin-right: 10px;
  margin-bottom: 10px;
}

.nav {
  transition: border-bottom-color 0.3s ease;
  padding: 5px;
  // width: 100px;
  text-align: center;
  font-weight: bold;
  // marginBottom: "10px",
  font-size: 16px;
}

.arrow {
  top: 0;
  right: -20px;
  left: -2px !important;
  width: 0;
  height: 0;
  border-top: 25px solid transparent /* Adjust the size of the triangle */;
  border-bottom: 25px solid transparent /* Adjust the size of the triangle */;
  border-left: 25px solid #19575c /* Color should match background color */;
}

.nav-heading {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #e8eeef;
  // padding:"10px",
  align-items: center;
  // width: 680px;
}

.scroll {
  height: 99%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.shipment-title {
  padding: 10px;
  background-color: #19575c;
  color: #e8eeef;
}

.shipment-details {
  width: 200px;
  height: 70px;
  background-color: #e8eeef;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image-div-div {
  display: flex;
  flex-wrap: wrap;
  width: 695px;
  justify-content: flex-start;
}

@media (max-width: 720px) {
  .shipment-card__container {

    &.scanbox-specific-mobile-bug {
      width: 300px !important;
    }}
  .image {
    width: 100%;
    height: 300px;
    cursor: pointer;
    border-radius: 5px;
    object-fit: cover;
  }
  .image-div-div {
    display: contents;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
  }
  .shipment-details {
    width: 94%;
    height: 70px;
    background-color: #e8eeef;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .image-div {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.nav{
  margin-left: 0px !important;
}

.cam-icon{
  margin-right: 10px;
}