.marker-cluster {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: inline-block;
    line-height: 300%;
    text-align: center;

    border: 1px solid var(--shlk-corporate);
    background-color: white;
}