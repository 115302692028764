.input-datalist {
    .bc-wrapper & .form-input {
        height: 40px;
    }

    .input-element {
        margin: 0 0 0 10px;
    
        &::-webkit-calendar-picker-indicator {
            opacity: 100;
        }
    }
}