@keyframes pulse {
    0% { transform: scale(1, 1) }
    50% { transform: scale(1.2, 1.2) }
    100% { transform: scale(1, 1) }
}

.marker-live-tracker {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    padding: 2px;
    background-color: var(--shlk-corporate);
    fill: var(--shlk-white);

    &.marker-live-tracker__active {
        height: 12px;
        width: 12px;
        background-color: #FFF;
        fill: var(--shlk-corporate);
        box-shadow: 0px 0px 3px 2px var(--shlk-corporate);
        animation: pulse 2s infinite linear;
        position: absolute;
       
    }
}