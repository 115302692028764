
.form-selectable {
    display: flex;
    align-items: center;

    &-title {
        line-height: 100%;
        font-size: 13px;
        font-weight: bold;
    }

    &-check {
        display: none;
    }

    &-icon {
        cursor: pointer;
        color: var(--shlk-corporate);  
        margin-right: 5px;
    }

    &__disabled {
        opacity: 0.3;
    }
}