.shlk-admin-all {
    .shlk-map {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;  
    }  

    .box-title {
        display: inline-block;
        margin-bottom: 5px;
    }

    .tracker-name {
        font-size: 18px;
        margin-bottom: 0;
    }

    .tracker-popup {
        &-title {
            font-size: 18px;
            margin:  0 0 10px 0;
        }

        &-field {
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
}
