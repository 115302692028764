.shlk-status {
  .shlk-title {
    color: var(--shlk-marine);
  }

  .shlk-card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: "Roboto Condensed";

    .event-count {
      color: var(--shlk-marine);
    }

    .shlk-status__header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      min-width: 40%;
      margin-bottom: 10px;
      color: var(--shlk-marine);

      .status-title__dot {
        margin-right: 20px;
        border: 8px solid;
        border-radius: 100px;
        min-height: 40px;
        min-width: 40px;

        &.status-ok {
          border-color: var(--shlk-green);
        }

        &.status-warn {
          border-color: var(--shlk-red);
        }
      }
    }

    .shlk-status__body {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      flex-grow: 1;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      width: 100% !important; // Adjusted width for smaller screens
      margin: 0 auto; // Center the container horizontally
    }

    &__header {
      // Adjust header styles for smaller screens
    }

    &__body {
      // Adjust body styles for smaller screens
    }
  }
}

@media (max-width: 1500px) {
  .responsive-width {
    width: 230px;
  }
}
@media (min-width: 1501px) {
  .responsive-width {
    width: 320px;
  }
}

.label-font {
  font-size: 13px;
  font-weight: bold;
  color: #19575c;
  // paddingLeft: 10px;
  text-align: center;
  padding: 0px 5px;
  height: 30%;
}

.value-font {
  font-size: 35px;
  font-weight: bold;
  color: #19575c;
  // paddingLeft: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.left-bottom-div {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  width: 23%;
  // marginBottom: 15px;
  display: flex;
  flex-direction: column;
  // borderRadius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-evenly;
  border-radius: 10px;
}

.unit-font {
  font-size: 13px;
  font-weight: normal;
  color: #3d9aa1;
}
