.marker-event {
    color: white;
    border-radius: 50%;
    padding: 3px;
    
    &--unack {
        background-color: var(--shlk-red);
    }

    &--ack {
        background-color: var(--shlk-marine);
    }

}