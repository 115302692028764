@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.mode-selector-container {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.mode-button {
  width: 120px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--shlk-corporate);
  border-radius: 10px;
  padding: 10px;
  color: var(--shlk-corporate);

  &:hover:not(:disabled) { // Apply hover styles only to buttons that are not disabled
    background-color: var(--shlk-corporate);
    color: var(--shlk-white);
  }

  &.selected:not(:disabled) {
    background-color: var(--shlk-marine);
    color: var(--shlk-white);
    border-color: var(--shlk-marine);
  }

  &:disabled { // Styles for disabled buttons
    color: #ccc; // Gray out text color
    border-color: #ccc; // Gray out border color
    background-color: #f4f4f4; // Light gray background
    cursor: not-allowed; // Change cursor to indicate the button is disabled
  }

  .material-icons {
    font-size: 24px;
    margin-bottom: 4px;
  }

  .mode-button-text {
    font-size: 12px;
    color: inherit;
    text-align: center;
  }
}
