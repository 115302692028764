.shlk-account-management {
  h1,
  h3 {
    margin: 0;
    margin-bottom: 15px;
  }

  .company-name {
    border-top: 1px solid var(--shlk-marine);
    padding: 15px;

    &:last-child {
      border-bottom: 1px solid var(--shlk-marine);
    }
  }

  .user-account {
    display: flex;
    gap: 15px;
    border-top: 1px solid var(--shlk-marine);
    padding: 15px;

    h4,
    p {
      margin: 0;
    }
  }
}

.shlk-layout {
  display: flex;
  justify-content: left;
  margin: 20px;
}

.layout-flex {
  display: flex;
  justify-content: space-between;
}

.shlk-companies,
.shlk-shipments {
  flex: 1;
}
.raised-card {
  padding: 20px;
  background-color: #e9eceb;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

  transition: box-shadow 0.3s ease-in-out;

  //Aligns the text to the center
  text-align: center;
}

.cards {
  width: 17%;
  .scrollable-list {
    text-align: left;
    list-style: none;
    padding: 0;
    min-height: 700px;
    max-height: 700px; // Adjust this value as needed
    overflow-y: auto;
    width: 100%;

    li {
      margin-bottom: 10px;
    }
  }
}

.map-holder {
  min-height: 500px;
  max-height: 500px; // Adjust this value as needed
  width: 100%;
  margin-left: 20px;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 59%; /* adjust this value as needed */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 100%; // adjust this value as needed
  margin-left: 20px;
}

.column {
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 10px;
  background-color: var(--shlk-marine);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: white;
  margin: 10px;
  height: 80%; // adjust this value as needed

  h3 {
    //Make the text bold
    font-weight: bold;
  }

  h1 {
    //Make the text bold
    font-weight: bold;
    font-size: 72px;
  }
}

@media (max-width: 720px) {
  .shlk-layout {
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 20px;
  }
  .cards {
    width: 90%;
    .scrollable-list {
      text-align: left;
      list-style: none;
      padding: 0;
      min-height: 100%;
      max-height: 100%; // Adjust this value as needed
      overflow-y: auto;
      width: 100%;
  
      li {
        margin-bottom: 10px;
      }
    }
  }
  .left-column {
    display: flex;
    flex-direction: column;
    width: 90%; /* adjust this value as needed */
  }
  .map-holder {
    min-height: 100%;
    max-height: 100%; // Adjust this value as needed
    width: 100%;
    margin-left: 0px;
  }
  .row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%; // adjust this value as needed
    // margin-left: 20px;
    width: 100%;
  }
  
}
