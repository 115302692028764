
.shipment-mapsettings {
    .mapsettings-option {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        margin-bottom: 10px;
    }

    .dropdown-title {
        font-size: 14px;
        font-weight: bold;

        .dropdown-icon {
            margin-left: 5px;
        }
    }

    .mapsettings-title {
        font-size: 15px;
        margin-bottom: 8px;
    }

    .mapsettings-subtitle {
        margin: 0 5px;
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;
    }

    .mapsetting-color {
        display: inline-flex;
        align-items: center;

        span {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border: 1px solid black;
        }

        &-tower {
            background-color: #CADC9B;
        }
        &-wifi {
            background-color: #F2E058;
        }
        &-vessel {
            background-color: #9E8DDF;
        }
        &-tracker {
            background-color: #ADF1F5;
        }
        &-cluster {
            background-color: white;
        }
        &-na {
            background-color: #E8827D;
        }
    }
}

