.history-shipments {
    .shipments-container {
        margin-bottom: 15px;

        .shipments-container__title {
            font-size: 16px;
            margin: 10px 0px 10px 5px;
            font-weight: 600;
        }

        .history-shipment {
            &-title {
                font-size: 18px;
                margin-top: 5px;
                margin-bottom: 0px;
            }

            &-field {
                font-size: 12px;
                margin-top: 5px;
            }
        
            &-button {
                border-width: 1px;
                display: inline-flex;
                align-items: center;
                padding: 2px 4px;
                border-radius: var(--shlk-border-radius);
                margin-top: 5px;
                font-weight: unset;
                width: 50px;
            }
        }
    }
}