.shlk-admin-company {
    h1, h3 {
        margin: 0;
        margin-bottom: 15px;
    }
    
    .form-label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
}