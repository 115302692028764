.shlk-widget-map {
  .shlk-title {
    display: flex;
    align-items: center;
    color: var(--shlk-marine);
  }

  .shlk-title__link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0px;

    .shlk-title__icon {
      background-color: var(--shlk-corporate);
      border-radius: 50%;
      color: white;
      text-align: center;
    }

    .shlk-title__text {
      flex-grow: 1;
      margin: 10px 0;
    }
  }

  .shlk-widget-location {
    padding: 0;
    height: 400px;
    position: relative;

    .shlk-location-map {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}

