.settings-input {
    margin-right: 10px;
    box-sizing: border-box;
    position: relative;
    max-width: 180px;

    &__name {
        text-transform: capitalize;
        color: var(--shlk-marine);
        font-size: 12px;
        pointer-events: none;
        display: block;
    }
    
    &__field {
        color: var(--shlk-marine); 
        background-color: transparent;
        border: none;
        border: 1px solid var(--shlk-digital);
        border-radius: var(--shlk-border-radius);
        width: 100%;
        padding: 4px;
    }

    &__unit {
        color: var(--shlk-marine);
        font-size: 12px;
        text-align: right;
        pointer-events: none;
    }

    .settings-input__field {
        background-color: var(--shlk-digital-alfa);
        margin-top: 2px;

        &.invalid {
            border: 1px solid var(--shlk-red);

            &:focus {
                outline: 1px solid var(--shlk-red);
            }
        }
    }

    .settings-input__validation {
        font-size: 10px;
        margin-top: 5px;
        font-weight: bold;
    }
}