.report-footer-spacer {
    height: 100px;
    width: 100%;
}

.report-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    .footer-logo {
        width: 250px;
        margin-right: -15px;
    }

    p {
        margin: 0;
        font-size: 12px;

        a {
            color: var(--shlk-corporate)
        }
    }

    background-color: #EEE !important;
    print-color-adjust: exact; 
    -webkit-print-color-adjust: exact; 

    @media not print {
        display: none;
    }
}