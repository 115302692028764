// // .shlk-tags {
// //     .tags-header {
// //         display: flex;
// //         justify-content: space-between;
// //         flex-wrap: wrap;
// //         align-items: center;
// //         margin-top: 30px;

// //         .tags-title {
// //             margin: 0;
// //             font-size: 28px;
// //             margin-bottom: 10px;
// //         }
// //     }

// //     .tags-container {
// //         flex-wrap: wrap;

// //         .tags-tag {
// //             font-weight: 500;
// //             padding: 4px 10px;
// //             margin-right: 12px;
// //             margin-top: 10px;
// //         }
// //     }

// //     &.shlk-desktop {
// //         max-height: 500px;
// //         overflow: auto;
// //     }

// //     .tags-table {
// //         .tags-create {
// //             &-input, &-color {
// //                 padding: 0;
    
// //                 .form-input {
// //                     display: flex;
// //                     height: 40px;
// //                     width: 100%;
// //                     margin: 0;
// //                     border-radius: 0;
// //                     text-align: center;
// //                     box-sizing: border-box;
// //                 }
// //             }
    
// //             &-btn {
// //                 padding: 0;
                
// //                 .shlk-button {
// //                     display: flex;
// //                     margin: auto;
// //                     justify-content: center;
// //                     text-align: center;
// //                     width: 100%;
// //                     height: 40px;
// //                     align-items: center;
// //                     border-radius: 0;
    
// //                     .material-icons {
// //                         margin: 0;
// //                     }
// //                 }   
// //             }
// //         }
// //     }

// // }

// .shlk-tags {
//     // Styling for the header of tags section
//     .tags-header {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         flex-wrap: wrap;
//         margin-top: 30px;

//         .tags-title {
//             margin: 0;
//             font-size: 28px;
//             margin-bottom: 10px;
//             font-weight: bold; // Adding font weight for emphasis
//         }
//     }

//     // Container for tags
//     .tags-container {
//         display: flex;
//         flex-wrap: wrap;
//         margin-top: 10px; // Add margin to the top of the container

//         .tags-tag {
//             font-weight: 500;
//             padding: 4px 10px;
//             margin: 5px; // Unified margin to handle spacing around each tag
//             background-color: #f1f1f1; // Add a light background for better visibility
//             border-radius: 4px; // Rounded corners for a softer look
//             box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); // Subtle shadow for better separation
//             white-space: nowrap; // Prevent tags from breaking into multiple lines
//         }
//     }

//     // Styling for desktop layout
//     &.shlk-desktop {
//         max-height: 500px;
//         overflow-y: auto; // Changed to overflow-y to handle vertical scrolling
//     }

//     // Styling for tag creation elements
//     .tags-table {
//         .tags-create {
//             &-input, &-color {
//                 padding: 0;

//                 .form-input {
//                     display: flex;
//                     height: 40px;
//                     width: 100%;
//                     margin: 0;
//                     border-radius: 4px; // Adding some radius for better aesthetics
//                     text-align: center;
//                     box-sizing: border-box;
//                     border: 1px solid #ccc; // Adding border to the input for visual separation
//                 }
//             }

//             &-btn {
//                 padding: 0;

//                 .shlk-button {
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     width: 100%;
//                     height: 40px;
//                     border-radius: 4px; // Rounded corners for the button
//                     background-color: #007bff; // Primary color background for button
//                     color: white; // White text color for contrast
//                     border: none; // Remove default border
//                     cursor: pointer; // Indicate clickable button

//                     .material-icons {
//                         margin: 0;
//                         font-size: 20px; // Adjust icon size for better visibility
//                     }
//                 }
//             }
//         }
//     }
// }
.shlk-tags {
    // Styling for the header of tags section
    .tags-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;

        .tags-title {
            margin: 0;
            font-size: 28px;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    // Container for tags
    .tags-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        .tags-tag {
            font-weight: 500;
            padding: 4px 10px;
            margin: 5px;
            background-color: #f1f1f1;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            white-space: nowrap;
        }
    }

    // Styling for desktop layout
    &.shlk-desktop {
        max-height: 500px;
        overflow-y: auto;

        // Webkit scrollbar styling
        ::-webkit-scrollbar {
            width: 16px; // Width of the scrollbar
            height: 16px; // Height of the horizontal scrollbar
        }

        ::-webkit-scrollbar-thumb {
            background-color: #888; // Color of the draggable part
            border-radius: 8px; // Rounded corners for the thumb
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #555; // Darker color on hover
        }

        ::-webkit-scrollbar-track {
            background-color: #f1f1f1; // Color of the track
            border-radius: 8px; // Rounded corners for the track
        }
    }

    // Styling for tag creation elements
    .tags-table {
        .tags-create {
            &-input, &-color {
                padding: 0;

                .form-input {
                    display: flex;
                    height: 40px;
                    width: 100%;
                    margin: 0;
                    border-radius: 4px;
                    text-align: center;
                    box-sizing: border-box;
                    border: 1px solid #ccc;
                }
            }

            &-btn {
                padding: 0;

                .shlk-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    border-radius: 4px;
                    background-color: #007bff;
                    color: white;
                    border: none;
                    cursor: pointer;

                    .material-icons {
                        margin: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
