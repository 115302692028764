
.multimodal-metric {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding: 15px; // Adjust overall padding as needed
    width: 100%;
    color: azure;
    height: auto; // Fallback value for unsupported 'fit-content'

    //Background color and soft shadow as card  
    background-color:var(--shlk-marine);
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin: 10px;

    //Reduce margin for h2
    h2 {
      margin: 0 0 10px 0;
    }

  
    .metric-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: top; // Ensure vertical alignment of rows
      width: 100%;
    
      .metric-item {
        display: flex;
        flex: 1; // Make each item equally fill the width
        flex-direction: column;
        margin: 10px 10px;
        align-items: center; // Center content vertically within each row
    
        .material-icons {
          margin-bottom: 5px;
        }
    
        span:nth-child(2) { // Target the value span for alignment
          text-align: right; // Right-align values
        }
      }
    }
  }
  