@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.mode-icon-container {
  display: flex;
  justify-content: left; // Left the icons in the container
  align-items: center;
  flex-wrap: wrap; // Allow icons to wrap in smaller viewports
  gap: 10px; // Spacing between icons
  margin-top: 10px;
  // Do not add padding or width here if you want the container size to be based on its content
}

.mode-icon {
  cursor: pointer;

  -webkit-user-select: none; // Safari and iOS support
  -moz-user-select: none;    // Firefox support
  -ms-user-select: none;     // Internet Explorer support
  user-select: none;        // Prevent text selection

  transition: background-color 0.2s, color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px; // Set a fixed width for each icon
  height: 60px; // Set a fixed height for each icon
  border: 2px solid var(--shlk-corporate);
  border-radius: 10px;
  // Remove margin if using gap in .icon-container
  color: var(--shlk-corporate);

  &:hover {
    background-color: var(--shlk-corporate);
    color: var(--shlk-white);
  }

  &.selected {
    background-color: var(--shlk-marine);
    color: var(--shlk-white);
    border-color: var(--shlk-marine);
  }

  .material-icons {
    font-size: 24px; // Adjust if necessary
    margin-bottom: 4px;
  }

  .mode-icon-text {
    font-size: 12px; // Adjust if necessary
    color: inherit;
    text-align: center;
  }
}
