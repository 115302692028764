@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes loadicons {
    0% {
      transform: scale(0) translate3d(0, 0, 0);
    }
    11% {
      transform: scale(1.2) translate3d(0, 0, 0);
    }
    22% {
      transform: scale(1) translate3d(0, 0, 0);
    }
    33% {
      transform: scale(0) translate3d(0, 0, 0);
    }
}

.loader-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-round {
        display: inline-block;
        width: 50px;
        height: 50px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            border: 5px solid var(--shlk-grey);
            border-top: 5px solid var(--shlk-corporate);
            border-right: 5px solid var(--shlk-corporate);
            border-radius: 50%;
            box-sizing: border-box;
            animation: spin 1s infinite linear;
        }
    }

    .loader-icon {
        width: 24px;
        height: 24px;
        fill: var(--shlk-corporate);
        position: absolute;
        top: 13px;
        left: 13px;
        transform: scale(0) translate3d(0, 0, 0);

        &:nth-of-type(1) {
            animation: loadicons 4s infinite ease-in-out;
        }
        &:nth-of-type(2) {
            animation: loadicons 4s 3s infinite ease-in-out;
        }
        &:nth-of-type(3) {
            animation: loadicons 4s 5s infinite ease-in-out;
        }
    }
}