
.shlk-user {
  .user-token-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;

    .user-token-icon {
      width: 30px;
      height: 30px;
      fill: var(--shlk-corporate);
      margin-left: 5px;
    }
  }

  .user-token {
    overflow: hidden;
    display: block;
    background: var(--shlk-transparent);
    border: 1px solid var(--shlk-corporate);
    border-radius: var(--shlk-border-radius);
    overflow-wrap: anywhere;
    padding: 5px;
  }
  
  .user-company {
    margin-top: 50px;
    width: fit-content;
  }

  .user-button {
    display: flex;
    align-items: center;

    .user-button-icon {
      width: 20px;
      height: 20px;
      fill: var(--shlk-transparent)
    }

    span {
      margin-left: 5px;
    }
  }

  .user-checkboxes {
    display: flex;
    align-items: center;
    margin: 5px 10px 0 0;
    padding: 0 0 5px 0;

    .form-selectable {
      margin: 0;
    }
  }

  .user-label {
    margin: 0 15px 0 0;
    font-weight: bold;
  }

  .user-field-invalid {
    .form-input {
      border: 2px solid var(--shlk-red);

      &:focus {
        outline: 1px solid var(--shlk-red);
      }
    }
  }

  .user-field {
    margin-top: 10px;

    .user-docs-link {
      text-align: right;
      margin: 2px 1px 0 0;

      b {
        color: var(--shlk-marine);
      }
    }
  }

  .form-label {
    display: block;

    .form-input {
      width: 200px;
    }

    .form-label__name {
      display: inline-block;
      margin-right: 10px;
    }
  }
}