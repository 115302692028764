@-webkit-keyframes rotating {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}

.shipment-docs { 
    .docs-title {
        font-size: 21px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    .docs-file {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        padding-bottom: 10px;
        border-bottom: 1px solid var(--shlk-grey);

        .docs-file-header {
            flex-grow: 1;
        }

        .docs-file-title {
            margin: 0;
            font-size: 14px;
        }

        .docs-file-remove {
            margin: 0;
            font-size: 10px;
            color: var(--shlk-red);
            font-weight: bold;
            display: inline-block;
        }

        .docs-button {
            text-align: center;
            padding: 0 5px;

            .docs-icon {
                background-color: var(--shlk-corporate);
                border-radius: 50%;
                height: 24px;
                width: 24px;
                padding: 3px;
                box-sizing: border-box;
                margin: auto;
                fill: white;

                svg {
                    vertical-align: top;
                }

                &.docs-loading {
                    -webkit-animation: rotating 1s linear infinite;
                }
            }
        }

        .docs-icon-caption {
            font-size: 8px;
            margin: 0;
            color: #777;
            margin-top: 2px;
        }

        .docs-close.docs-icon {
            fill: white;
            background-color: var(--shlk-red);
        }
    }

    .documents-form {
        margin-top: 15px;
    }

    .filepond--file {
        background-color: var(--shlk-corporate);
        color: aqua;
    }
}