.tag-configuration {
    .form-label {
        display: inline-flex;
        align-items: center;
        margin: 0 0 15px 0;
        width: 250px;

        .form-input {
            margin-bottom: 0;
            flex-grow: 1;
        }
    }
    .form-settings {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .settings-input__field {
            max-width: 50px;
        }
    }
    .form-settings-create {
        margin-top: 0;
    }

    &-color {
        position: relative;

        .tag-configuration-input {
            padding-right: 30px;     
        }

        .tag-configuration-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            pointer-events: none;
            color: var(--shlk-marine);
        }
    }

    &-content {
        display: flex;
        gap: 15px;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    &-group {
        display: flex;
        flex-direction: column;
    }

    &-title {
        margin: 0 0 15px 0;
    }

    &-actions {
        display: flex;
        justify-content: right;
        gap: 15px;
        flex-wrap: wrap;

        .shlk-button {
            display: flex;
        }

        .conf-delete {
            background-color: var(--shlk-red);
            border-color: var(--shlk-red);
        }
    }
}

@media screen and (max-width: 767px) {
    .mobile{
        width: 100%;
    }
}