/*
* Naxxar App general Styles
*/

:root {
  --shlk-marine: #19575c;
  --shlk-corporate: #3d9aa1;
  --shlk-digital: #91efef;
  --shlk-corporate-complementary: #dbe3f3;
  --shlk-transparent: #eef2f2;
  --shlk-accent: #fcd82d;
  --shlk-corporate-light: #e8eeef;

  --shlk-digital-alfa: #ced7e7;
  --shlk-black: #333;
  --shlk-white: #fafafa;
  --shlk-green: #1cb12f;
  --shlk-red: #c11c1c;
  --shlk-coral: #ff7f7f;
  --shlk-grey: #ced7e7;

  --shlk-border-radius: 9px;

  font-family: "Roboto Condensed";
}

html {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--shlk-corporate);
    border-radius: var(--shlk-border-radius);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--shlk-transparent);
  }

  scrollbar-color: var(--shlk-digital) var(--shlk-transparent);
  scrollbar-width: thin;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  color: var(--shlk-marine);
  background-color: var(--shlk-transparent);
  font-size: 15px;

  &.shlk-open-card {
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}

textarea {
  font-family: inherit;
}
