.box-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border-width: 1px;
    cursor: pointer;

    &-icon {
        margin-right: 5px;
    }
}