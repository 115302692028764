.company-add {
    .add-buttons {
        margin-top: 20px;
        display: flex;
        gap: 10px;

        .shlk-mobile & {
            display: grid;
        }

        .shlk-button {
            flex-grow: 1;
            width: 100%;
        }
    }

    .add-inputs {
        margin-top: 20px;
        display: flex;
        gap: 10px;

        .shlk-mobile & {
            display: grid;
        }

        .add-input-invalid {
            border: 2px solid var(--shlk-red);

            &:focus {
                outline: 1px solid var(--shlk-red); 
            }
        }
        
        .form-label {
            margin-right: 0;
            flex-grow: 1;
            width: 100%;
    
            .form-label__name {
                display: block;
                font-size: 15px;
                font-weight: bold;
                text-transform: uppercase;
                color: var(--shlk-marine);
            }
            .form-input {
                width: 100%;
            }
        }
    }

    .add-error {
        font-size: 13px;
        text-align: center; 
        color: var(--shlk-red); 
        
        h3 {
            margin: 0;
            margin-top: 15px;
        }
    }
}