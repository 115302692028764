input.form-input {
    box-sizing: border-box;
    color: var(--shlk-corporate);
    background-color: var(--shlk-corporate-complementary);
    border-radius: var(--shlk-border-radius);
    border: 0;
    padding: 0 10px;
    height: 30px;
    min-width: 185px;
    outline: none;

    &::placeholder {
        color: var(--shlk-corporate);
        opacity: 0.7;
    }

    &[type=submit] {
        color: white;
        background-color: var(--shlk-corporate);
        border: 1px solid white;
    }

    &[type=color] {
        width: 50px;
    }

    &[disabled] {
        background-color: var(--shlk-corporate-complementary);
    }
}

label.form-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    color: var(--shlk-marine);
    border-radius: var(--shlk-border-radius);
    vertical-align: middle;
    gap: 0 5px;
    justify-content: space-between;

    span.form-label__name {
        display: inherit;
    }

    input.form-input {
        display: inline-block;
    }

    .form-icon {
        position: absolute;
        fill: var(--shlk-corporate);
        top: 8px;
        right: 6px;
        height: 20px;
        width: 20px;
    }

    .form-string-icon {
        padding-left: 5px;
    }
}

.From-style{
    display: contents !important;
}