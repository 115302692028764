.report-header {
    text-align: center; 
    color: var(--shlk-corporate);

    &-content {
        display: flex;

        .report-header-info {
            flex-grow: 1;
        }
    }

    .report-title {
        margin: 20px 0 0 0;
        font-size: 30px;
        font-weight: normal;

        > span {
            font-weight: bold;
        }
    }
    .report-date {
        margin: 0;
        font-weight: normal;
        opacity: 0.5;
    }  

    .report-header-icon {
        display: flex;
        align-items: center;
        height: 50px;
        box-sizing: border-box;
        margin-left: 10px;
    }
}